import React, { useEffect, useState } from "react";
import cl from "./ResedentialComplex.module.css";
import utils from "../../utils";
import axios from "axios";
import api from "../../api";
import { useParams } from "react-router-dom";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";

export const ResedentialComplex = () => {
  const [data, setData] = useState(null);
  const id = useParams().id;
  const [isLoading, setIsLoading] = useState(true);
  const loadData = async () => {
    const res = await axios.get(
      api.serverUrl + "/api/v1/residential-complex/" + id
    );
    if (res?.data?.residential_complex) {
      console.log(res.data);
      setData(res.data.residential_complex);
      setIsLoading(false);
    }
  };

  const keyMapping = {
    "ceilings.height": "Высота потолков",
    "count.of.apartments": "Количество квартир",
    "count.of.floors": "Количество этажей",
    facade: "Фасад",
    "facing.type": "Тип отделки",
    flatForSaleNb: "Квартир в продаже",
    heating: "Отопление",
    "home.type": "Тип дома",
    housingClass: "Класс жилья",
    kitchen: "Кухня",
    lifts: "Лифты",
    parking: "Парковка",
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={`lg:container ${cl.main}`}>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <LoadingWrapper size='20px' loading={isLoading}></LoadingWrapper>
        </div>
      ) : (
        <>
          <div className={"lg:container grid grid-cols-12"}>
            {!!data.images?.length && (
              <div className='col-span-12 xl:col-span-8 mb-1 xl:mb-0'>
                <img
                  className='w-full h-full max-h-[20vh] xl:max-h-[60vh] object-cover xl:rounded-xl cursor-pointer'
                  src={utils.singleImageLink(data.images[0])}
                  alt='gallery'
                />
              </div>
            )}
            {data.images?.length > 1 && (
              <div className='col-span-12 xl:col-span-4 flex flex-col xl:pl-2.5'>
                {data.images.slice(1, 4).map((image, idx) => (
                  <img
                    className='w-full h-full max-h-[20vh] xl:max-h-[18vh] mb-1 xl:mb-[3vh] object-cover xl:rounded-xl cursor-pointer'
                    src={utils.singleImageLink(image)}
                    alt='gallery'
                  />
                ))}
              </div>
            )}
          </div>
          <div className={cl.title}>{data.residential_complex_name}</div>
          <div className={cl.address}>{data.address_string}</div>
          <div className={cl.descriptionBlock}>
            <div className={cl.descriptionTitle}>Описание</div>
            <div className={cl.description}>{data.description}</div>
          </div>
          <div className={cl.infoTitle}>
            <h2>О доме</h2>
          </div>
          <div className={cl.infoBlock}>
            {Object.entries(data.json_params).map(([key, value]) => (
              <div className={cl.line} key={key}>
                <div className={cl.left}>{keyMapping[key] || key}</div>
                <div className={cl.right}> {value}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
