import Switch from "../../components/Buttons/Switch";
import ListIconSelector from "../../components/Selectors/ListIconSelector";
import { customTypes, rawData } from "../../constants";
import useGlobalConfigState from "../../stores/config"
import useGlobalSearchState from "../../stores/search"

const addSpaces = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

const formatNumber = (val, type) => type === 'price' ? addSpaces(removeNonNumeric(val)) : removeNonNumeric(val)

const FilterItem = ({ type, margin = true, border = 'border-[1.5px] border-touchable' }) => {
    const searchState = useGlobalSearchState()
    const configState = useGlobalConfigState()
    const config = configState.get()
    const form = searchState.getValue('form')
    const filterType = customTypes.find(ct => ct.fields.includes(type))?.filterType || config.propLabels?.find(pl => pl.property === type)?.filter_type || ''
    const updateForm = (fType, item, key) => () => {
        switch (fType) {
            case 'switch':
                searchState.mergeForm({
                    [type]: item
                })
                break
            case 'list':
            case 'list-muliple':
                searchState.mergeForm({
                    [type]: item === "all" ? [] : form[type].includes(item.id) ? form[type].filter(i => i !== item.id) : [...form[type], item.id]
                })
                break
            case 'range':
                if (isNaN(parseInt(item.replace(/ /g, "_"))) && item != '') {
                    return
                }
                const finalValue = formatNumber(item, type)
                console.log(finalValue)
                searchState.mergeForm({
                    [type]: form[type] instanceof Object ?
                        {
                            ...form[type],
                            [key]: finalValue
                        } :
                        {
                            [key]: finalValue,
                            [key === "from" ? "to" : "from"]: ''
                        }
                })
        }
    }
    if (filterType) {
        switch (filterType) {
            case 'switch':
                const first = (rawData[type] || rawData.deal_type)[0]
                const second = (rawData[type] || rawData.deal_type)[1]
                return (
                    <Switch
                        value={form[type]}
                        val1={first.value}
                        val2={second.value}
                        label1={first.label}
                        label2={second.label}
                        setValue={(value) => updateForm('switch', value)()}
                    />
                )
            case 'icon-list':
                let filteredPt
                if (config && config.propertyTypes) {
                    filteredPt = config.propertyTypes.filter(pt => {
                        return pt.listing_type === form.listing_type
                            &&
                            !!config.listingStructures.find(ls => form.deal_type === ls.deal_type &&
                                pt.id === ls.property_type &&
                                pt.listing_type === ls.listing_type
                            )
                    })
                }
                return (
                    <ListIconSelector
                        prop={type}
                        value={form[type]}
                        values={filteredPt}
                        setValue={(value) => updateForm('switch', value)()}
                    />
                )
            case 'list':
            case 'list-multiple':
                return (
                    <div className="flex flex-row gap-y-4 flex-wrap mt-5 mb-5 overflow-x-scroll pb-2">
                        <div
                            className={`
                                cursor-pointer border border-touchable py-2 px-5 mr-2 rounded-xl whitespace-nowrap
                                ${form[type]?.length === 0 ? 'bg-touchable-selected text-primary' : 'bg-white text-info-gray'}
                            `}
                            onClick={updateForm('list', 'all')}
                        >
                            <span>Все</span>
                        </div>
                        {config.propMapping[type] && config.propMapping[type].list?.map(item => {
                            let itemName = item.name
                            if (type === 'num_rooms' && item.id === 5) {
                                itemName = '5+'
                            }
                            return (
                                <div
                                    className={`
                                        cursor-pointer border border-touchable py-2 px-5 mr-2 rounded-xl whitespace-nowrap
                                        ${form[type]?.includes(item.id) ? 'bg-touchable-selected text-primary' : 'bg-white text-info-gray'}
                                    `}
                                    onClick={updateForm('list', item)}
                                >
                                    <span>{itemName}</span>
                                </div>
                            )
                        })}
                    </div>
                )
            case 'range':
                return (
                    <div className={`flex flex-row ${margin ? 'my-5' : ''} items-center`}>
                        <input
                            className={`${border} bg-white rounded-xl px-4 py-2 max-w-40`}
                            value={form[type]?.from || ''}
                            onChange={e => updateForm('range', e.target.value, 'from')()}
                        />
                        <span className="ml-2 mr-2">
                            до
                        </span>
                        <input
                            className={`${border} bg-white rounded-xl px-4 py-2 max-w-40`}
                            value={form[type]?.to || ''}
                            onChange={e => updateForm('range', e.target.value, 'to')()}
                        />
                    </div>
                )
        }
    }
}

export default FilterItem