import { campaignAssets } from "../../constants"

export const CampaignSingle = ({campaign, isSelected, setSelected}) => {
    const assetMeta = campaignAssets.find(a => a.type === campaign.type)
    if(!assetMeta) {
        return null
    }

    return (
        <div 
            className={`rounded-xl cursor-pointer border border-solid border-touchable bg-campaign p-4 mt-2.5 z-10 overflow-hidden ${isSelected ? `promotion-${campaign.type}-selected` : ''}`}
            onClick={setSelected(campaign)}
        >
            <div className="flex flex-row">
                <div className="flex-[7] flex flex-col">
                    <p className={`text-xl font-bold ${isSelected ? 'text-white' : 'text-typography'}`}>
                        {campaign.title}
                    </p>
                    <p className={`font-medium mt-1.5 ${isSelected ? 'text-white' : 'text-typography'}`}>
                        {campaign.description}
                    </p>
                    <p className={`text-2xl font-bold mt-3.5 ${isSelected ? 'text-white' : 'text-typography'}`}>
                        {campaign.price} ₸/день
                    </p>
                </div>
                <div className="flex-[3] flex flex-col relative">
                    <img 
                        className="absolute right-[-17px] bottom-[-17px] overflow-hidden"
                        src={assetMeta.icon}
                    />
                </div>
            </div>
        </div>
    )
}