import { myListingActionOptions } from "../../constants"
import chevronRight from "../../assets/chevron-right.svg"
import { useGlobalMiscState } from "../../stores/misc"
import { useNavigate } from "react-router-dom"
import Modal from "../../components/Modal/Modal"
import { PromoteModal } from "./PromoteModal"

export const ListingOption = ({type, listing}) => {
    const navigate = useNavigate()
    const miscState = useGlobalMiscState()
    const profileListFetchTrigger = miscState.get('profileListFetchTrigger')
    const optionPromoteModalOpen = miscState.get('optionPromoteModalOpen')
    const meta = myListingActionOptions.find(a => a.type === type)
    if(!meta) {
        return null
    }

    const setOptionPromoteModalOpen = (val) => {
        miscState.set('optionPromoteModalOpen', val)
    }

    const handleClick = async () => {
        switch(meta.actionType) {
            case 'fetch':
                await meta.action(meta.apiFunction, meta.apiParams(listing.id))
                miscState.set('profileListFetchTrigger', profileListFetchTrigger + 1)
                break
            case 'navigate':
                navigate(meta.navigateTo(listing.id))
                break
            case 'promoteModal':
                miscState.set('optionSelectedListingId', listing.id)
                setOptionPromoteModalOpen(true)
                break
        }
    }

    return (
        <>
            <div 
                className="flex flex-row justify-between items-center cursor-pointer my-1"
                onClick={handleClick}
            >
                <span className={`font-bold leading-6 text-typography ${meta.labelClassName || ""}`}>
                    {meta.label}
                </span>
                <img src={chevronRight} />
            </div>
            <Modal
                dimensions="w-full h-full xl:w-2/5 xl:h-auto overflow-y-scroll rounded-md" 
                visible={optionPromoteModalOpen} 
                setVisible={setOptionPromoteModalOpen}
            >
                <PromoteModal />
            </Modal>
        </>
    )
}