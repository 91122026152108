import { useNavigate } from "react-router-dom";
import { listingInfoRender } from "../../constants";
import useGlobalConfigState from "../../stores/config";
import ListingKeyValue from "./ListingKeyValue";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import api from "../../api";

const ListingInfo = ({ title, listing, keys, renderType }) => {
  const configState = useGlobalConfigState();
  const config = configState.get();
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);

  const loadData = useCallback(async () => {
    const req = await api.katoApi.getKatoByParentArray(listing.kato_path);
    const data = req.data;
    const value = data.list?.map((i) => i.name).join(", ");
    setAddress(value + ", " + listing.address_string);
    return value;
  }, []);

  useEffect(() => {
    if (renderType === "main_info") {
      loadData();
    }
  }, [listing.id]);

  const renderMeta =
    listingInfoRender[renderType] || listingInfoRender.main_info;
  return (
    <>
      <p className='mt-6 mb-2.5 text-2xl text-typography font-bold'>{title}</p>
      <div className='flex flex-col w-full xl:w-1/2'>
        {keys.map((prop) => {
          let value = renderMeta.getValue(config, listing, prop);
          if (prop === "custom_full_address") {
            value = address;
          }
          if (!!value) {
            return (
              <ListingKeyValue
                label={
                  config.propLabels.find((l) => l.property == prop)?.label_ru ||
                  prop
                }
                key={prop}
                value={`${value} ${
                  prop === "floor" && listing.num_floors
                    ? ` из ${listing.num_floors}`
                    : ""
                }`}
                {...(prop === "residential_complex_name"
                  ? {
                      valueRender: () => (
                        <p
                          className='flex-1 text-lg cursor-pointer text-primary underline'
                          onClick={() =>
                            navigate(
                              `/residential/${listing.residential_complex}`
                            )
                          }
                        >
                          {value}
                        </p>
                      ),
                    }
                  : {})}
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default ListingInfo;
