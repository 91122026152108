import flat from "./assets/flat.svg";
import home from "./assets/home.svg";
import commercial from "./assets/commercial.svg";
import telegram from "./assets/telegram.svg";
import whatsapp from "./assets/whatsapp.svg";
import call from "./assets/call.svg";
import envelope from "./assets/envelope.svg";
import svgIcons from "./assets/svgs";
import statsEye from "./assets/stats-eye.svg";
import statsPhone from "./assets/stats-phone.svg";
import statsWhatsapp from "./assets/stats-whatsapp.svg";
import promotionStandart from "./assets/promotion-standart.svg";
import promotionTop from "./assets/promotion-top.svg";
import promotionButton from "./assets/promotion-button.svg";
import deleteScreenshotProfile from "./assets/delete-screenshot-profile.png";
import deleteScreenshotListItem from "./assets/delete-screenshot-list-item.png";
import deleteScreenshotConfirm from "./assets/delete-screenshot-confirm.png";
import { getValue } from "@testing-library/user-event/dist/utils";
import { accessGlobalMiscState } from "./stores/misc";
import api from "./api";
import commonActions from "./actions/common";
const dealTypes = [
  {
    label: "Арендовать",
    value: 1,
  },
  {
    label: "Купить",
    value: 2,
  },
];

const addDealTypes = [
  {
    label: "Сдать",
    value: 1,
  },
  {
    label: "Продать",
    value: 2,
  },
];

const listingTypes = [
  {
    label: "Жилая",
    value: 1,
  },
  {
    label: "Коммерческая",
    value: 2,
  },
];
const customTypes = [
  {
    filterType: "switch",
    fields: ["deal_type", "listing_type"],
  },
  {
    filterType: "icon-list",
    fields: ["property_type"],
  },
];

const listingTypeMeta = {
  deal_type: {
    list: {
      search: dealTypes,
      add: addDealTypes,
    },
    valueKey: "value",
    labelKey: "label",
  },
  listing_type: {
    list: listingTypes,
    valueKey: "value",
    labelKey: "label",
  },
  property_type: {
    list: "filtered",
    valueKey: "id",
    labelKey: "label_ru",
  },
};

const quickFiltersMeta = {
  deal_type: {
    additionalClass: "my-5 xl:my-2",
  },
};

const withdrawBonusesFields = [
  {
    name: "phone_number",
    label: "Номер телефона",
  },
  {
    name: "total",
    label: "Сумма чека",
    transform: "numeric",
  },
  {
    name: "amount",
    label: "Сумма списания",
    transform: "numeric",
  },
];

const initialKatoParent = { id: 0, parent_id: 0, name: "Казахстан" };

const property_type = [
  { label: "Квартира", value: 1 },
  { label: "Дом", value: 2 },
  { label: "Коммерческая недвижимость", value: 6 },
];

const listIcons = {
  property_type: {
    1: svgIcons.Flat,
    2: svgIcons.Home,
    6: svgIcons.Commercial,
  },
};

const rawData = {
  deal_type: dealTypes,
  listing_type: listingTypes,
};

const allRequiredFields = ["seller_name", "seller_phone_number"];

const additionalAddFields = {
  address_string: ["kato_path", ""],
};

const defaultGetValue = (config, listing, prop) => {
  return config.rawValues.includes(prop)
    ? listing[prop]
    : config.propMapping[prop]?.list.find((item) => item.id == listing[prop])
        ?.name ||
        config.propMapping[prop]?.list.find((item) => item.id == listing[prop])
          ?.label_ru ||
        listing[prop];
};

const listingInfoRender = {
  main_info: {
    getValue: defaultGetValue,
  },
  about_house: {
    getValue: defaultGetValue,
  },
};

const calculatorConfig = {
  totalCost: {
    label: "Стоимость недвижимости",
    colSpan: 12,
  },
  initialFee: {
    label: "Первоначальный взнос",
    colSpan: 12,
  },
  percentage: {
    label: "Ставка",
    colSpan: 6,
    additionalClass: "pr-[5px]",
  },
  term: {
    label: "Срок займа",
    colSpan: 6,
    additionalClass: "pl-[5px]",
  },
};

const searchResultMeta = {
  Search: {
    imageClassName:
      "col-span-6 xl:col-span-2 px-4 xl:px-10 search-result-image-wrapper",
    infoClassName: "col-span-6 xl:col-span-4 px-4 xl:px-0",
    hasDescription: true,
    hasPhone: true,
  },
  Listing: {
    imageClassName: "col-span-6 min-h-[15vh] pr-5",
    infoClassName: "col-span-6",
    hasDescription: false,
    hasPhone: false,
  },
  MyListings: {
    imageClassName: "col-span-6 xl:col-span-2 px-4 xl:px-10",
    infoClassName: "col-span-6 xl:col-span-4 px-4 xl:px-0",
    hasDescription: false,
    hasPhone: false,
  },
  ListingsToModerate: {
    imageClassName: "col-span-6 xl:col-span-2 px-4 xl:px-10",
    infoClassName: "col-span-6 xl:col-span-4 px-4 xl:px-0",
    hasDescription: false,
    hasPhone: false,
    showSimilar: false,
  },
};

const addImagesNumOfBlocks = {
  640: 2,
  1024: 4,
  1536: 6,
};

const authModalMeta = {
  phone: {
    title: "Вход или регистрация",
    subtitle: "Введите номер телефона, чтобы войти или зарегистрироваться",
  },
  otp: {
    title: "Введите код",
    subtitle: `Код отправлен на номер ${accessGlobalMiscState().get(
      "authPhoneNumber"
    )}`,
  },
};

const addListingMeta = [
  {
    props: ["deal_type", "listing_type", "property_type"],
    className: "col-span-12 xl:col-span-2 mb-3 mr-3",
    customAddType: "listing-type-select",
    labels: {
      deal_type: "Тип сделки",
      listing_type: "Жилая/Коммерческая",
    },
  },
  {
    props: [],
    default: true,
    className: "col-span-12",
  },
  {
    props: [
      "lat",
      "lng",
      "residential_complex_name",
      "residential_complex",
      "kato_path",
    ],
    exclude: true,
  },
  {
    props: [
      "seller_name",
      "seller_whatsapp",
      "seller_telegram",
      "seller_phone_number",
    ],
    className: "col-span-12",
    customAddType: "string",
    labels: {
      seller_name: "Ваше имя",
      seller_phone_number: "Номер телефона",
      seller_whatsapp: "Номер Whatsapp",
      seller_telegram: "Номер телефона/Имя пользователя Telegram",
    },
  },
];

const contactUsItems = [
  {
    key: "telegram",
    image: telegram,
    label: "Telegram",
    url: "https://t.me/kiltapp",
    mobileOnly: false,
  },
  {
    key: "whatsapp",
    image: whatsapp,
    label: "Whatsapp",
    url: "https://wa.me/77479909050",
    mobileOnly: false,
  },
  {
    key: "call",
    image: call,
    label: "Позвонить",
    url: "tel:+77479909050",
    mobileOnly: true,
    secondaryText: "+7 747 990 90 50",
  },
  {
    key: "email",
    image: envelope,
    label: "Написать на почту",
    url: "mailto:kilt.org.app@gmail.com",
    mobileOnly: true,
    secondaryText: "kilt.org.app@gmail.com",
  },
];

const profileTabs = [
  {
    tabId: "personal",
    label: "Личная информация",
  },
  {
    tabId: "blog",
    label: "Блог",
  },
  {
    tabId: "my-notifications",
    label: "Уведомления",
  },
  {
    tabId: "my-listings",
    label: "Мои объявления",
  },
  {
    tabId: "my-contracts",
    label: "Мои договоры",
  },
  {
    tabId: "my-rent-bookings",
    label: "Мои бронирования",
  },
  {
    tabId: "privacy-policy",
    label: "Политика конфиденциальности",
    url: "https://kiltapp.kz/html/privacy-policy.html",
  },
  {
    tabId: "rules",
    label: "Правила",
    url: "https://kiltapp.kz/uploads/rules.pdf",
  },
  {
    tabId: "contact-us",
    label: "Связаться с нами",
  },
  {
    tabId: "logout",
    label: "Выход",
  },
];

const partnerTabs = [
  {
    tabId: "withdraw",
    label: "Списать бонусы",
  },
  {
    tabId: "history",
    label: "История",
  },
];

const moderationTabs = [
  {
    tabId: "all",
    label: "Все объявления на модерации",
  },
];

const personalMeta = [
  {
    label: "Имя",
    prop: "firstname",
  },
  {
    label: "Фамилия",
    prop: "lastname",
  },
  {
    label: "Номер телефона",
    prop: "phone",
  },
];

const myListingOptions = [
  {
    label: "Активные",
    statuses: [1],
  },
  {
    label: "Модерация",
    statuses: [2, 3],
    additionalClassName: "opacity-50",
    disabled: true,
  },
  {
    label: "Архив",
    statuses: [0],
    additionalClassName: "opacity-50",
    disabled: true,
  },
];

const listingsToModerateOptions = [
  {
    label: "Все",
  },
];

const rentBookingStatusMapper = {
  0: "Ожидает подтверждения",
  1: "Подтверждено",
};

const blogMockData = [
  {
    createdAt: "28 марта 2024",
    image: "blog_1.png",
    title: "Когда в присоединённых районах Алматы появится качественная вода",
  },
  {
    createdAt: "30 марта 2024",
    image: "blog_2.png",
    title:
      "Проблема качественного водоснабжения в присоединённых районах Алматы постепенно решается",
  },
  {
    createdAt: "2 апреля 2024",
    image: "blog_3.png",
    title: "Инструкция покупки квартиры",
  },
];

const myNotificationsOptions = [
  {
    label: "Все",
  },
];

const myRentBookingsOptions = [
  {
    label: "Арендую",
  },
  {
    label: "Сдаю",
  },
];

const listingEventFilter = {
  clicks: {
    search: "listing_click_",
    label: "Просмотры",
    icon: statsEye,
  },
  phone: {
    search: "phone_from_",
    label: "Просмотры номера телефона",
    icon: statsPhone,
  },
  whatsapp: {
    search: "whatsapp_from_",
    label: "Переходы в Whatsapp",
    icon: statsWhatsapp,
  },
};

const mandatoryAddListingFields = [
  "seller_name",
  "seller_phone_number",
  "seller_whatsapp",
  "seller_telegram",
];

const editBlackList = [
  "deal_type",
  "listing_type",
  "property_type",
  "address_string",
];

const myContractsOptions = [
  {
    label: "Ожидают подписи клиента",
    key: "pending",
    color: "deal-status-pending",
    displayLabel: "Ожидает подписи клиента",
  },
  {
    label: "Подписанные",
    key: "completed",
    color: "deal-status-completed",
    displayLabel: "Подписанный",
  },
];

const profileLists = {
  listings: {
    showOptions: true,
    initialOption: "Активные",
    options: myListingOptions,
    optionSearchLabel: "label",
    passedParams: ["options", "selectedOption"],
    requestModel: "Listing",
    requestFilters: [
      {
        key: "seller_id",
        type: "userId",
      },
      {
        key: "status",
        type: "selectedOption",
        selectedOptionField: "statuses",
      },
    ],
    requestParams: {
      order: [["updatedAt", "DESC"]],
    },
  },
  contracts: {
    showOptions: true,
    initialOption: "Ожидают подписи клиента",
    options: myContractsOptions,
    customApi: api.dealsApi.myDeals,
    customResponseKey: "deals",
    customRequestParam: "userId",
    optionSearchLabel: "label",
    passedParams: ["options", "selectedOption"],
    requestModel: "Deal",
    listFilter: (selected) => (item) =>
      selected === "Ожидают подписи клиента"
        ? !item.to_filled
        : item.from_filled && item.to_filled,
  },
  "rent-bookings": {
    showOptions: true,
    initialOption: "Арендую",
    options: myRentBookingsOptions,
    optionSearchLabel: "label",
    passedParams: ["options", "selectedOption"],
    requestModel: "RentBooking",
    requestFilters: [
      {
        customKeyGetter: (selectedOption) =>
          selectedOption?.label === "Арендую" ? "user_id" : "owner_id",
        type: "userId",
      },
    ],
    requestParams: {
      order: [["updatedAt", "DESC"]],
    },
  },
  notifications: {
    showOptions: false,
    initialOption: "Все",
    options: myNotificationsOptions,
    optionSearchLabel: "label",
    passedParams: ["options", "selectedOption"],
    requestModel: "Notification",
    requestFilters: [
      {
        key: "user_id",
        type: "userId",
      },
    ],
    requestParams: {
      order: [["updatedAt", "DESC"]],
    },
  },
  blog: {
    showOptions: false,
    mockData: blogMockData,
    initialOption: "Все",
    options: myNotificationsOptions,
    optionSearchLabel: "label",
    passedParams: ["options", "selectedOption"],
    requestModel: "Blog",
    requestFilters: [
      {
        key: "user_id",
        type: "userId",
      },
    ],
    requestParams: {
      order: [["updatedAt", "DESC"]],
    },
  },
  "all-moderate": {
    showOptions: false,
    initialOption: "Все",
    options: listingsToModerateOptions,
    customApi: api.listingApi.listingsToModerate,
    customResponseKey: "listings",
    customRequestParam: "userId",
    optionSearchLabel: "label",
    passedParams: [],
    requestModel: "Listing",
  },
};

const myListingActionOptions = [
  {
    type: "publish",
    label: "Опубликовать",
    actionType: "fetch",
    action: commonActions.simpleGetter,
    apiFunction: api.listingApi.updateListing,
    apiParams: (id) => ({
      id,
      body: {
        status: 1,
      },
    }),
    triggerFetch: true,
  },
  {
    type: "sendToArchive",
    label: "Отправить в архив",
    actionType: "fetch",
    action: commonActions.simpleGetter,
    apiFunction: api.listingApi.updateListing,
    apiParams: (id) => ({
      id,
      body: {
        status: 0,
      },
    }),
    triggerFetch: true,
  },
  {
    type: "edit",
    label: "Редактировать",
    actionType: "navigate",
    navigateTo: (id) => `/edit-listing/${id}`,
  },
  {
    type: "promote",
    label: "Продвигать",
    actionType: "promoteModal",
    labelClassName: "promote-text",
  },
];

const campaignAssets = [
  {
    type: "standart",
    icon: promotionStandart,
  },
  {
    type: "top",
    icon: promotionTop,
  },
  {
    type: "button",
    icon: promotionButton,
  },
];

const listingModerateSellerRows = [
  {
    label: "Имя Фамилия",
    customRender: (item) => (
      <span className='flex-[7]'>
        {item.seller?.lastname || "(пусто)"}{" "}
        {item.seller?.firstname || item.seller_name || "(пусто)"}
      </span>
    ),
  },
  {
    label: "Номер телефона",
    renderKey: "seller_phone_number",
  },
  {
    label: "Тип пользователя",
    customRender: (item) => (
      <span className='flex-[7]'>
        {item.seller?.user_type === "owner"
          ? "Собственник недвижимости"
          : "Специалист"}
      </span>
    ),
  },
];

const deleteAccountItems = [
  {
    label: "1. Нажмите на свой профиль",
    image: deleteScreenshotProfile,
  },
  {
    label: '2. Нажмите на "Удалить аккаунт"',
    image: deleteScreenshotListItem,
  },
  {
    label: "3. Подтвердите удаление",
    image: deleteScreenshotConfirm,
  },
];

const fieldTransformKeys = ["numeric"];

export {
  addDealTypes,
  property_type,
  dealTypes,
  listingTypes,
  customTypes,
  rawData,
  listIcons,
  listingInfoRender,
  calculatorConfig,
  searchResultMeta,
  listingTypeMeta,
  initialKatoParent,
  authModalMeta,
  profileLists,
  rentBookingStatusMapper,
  myNotificationsOptions,
  blogMockData,
  contactUsItems,
  allRequiredFields,
  additionalAddFields,
  addListingMeta,
  personalMeta,
  profileTabs,
  partnerTabs,
  moderationTabs,
  quickFiltersMeta,
  withdrawBonusesFields,
  fieldTransformKeys,
  addImagesNumOfBlocks,
  myListingActionOptions,
  campaignAssets,
  listingEventFilter,
  mandatoryAddListingFields,
  editBlackList,
  myListingOptions,
  listingModerateSellerRows,
  deleteAccountItems,
};
