import ReactInputMask from "react-input-mask"
import { useGlobalMiscState } from "../../stores/misc"
import { useState } from "react"
import { authModalMeta } from "../../constants"
import OTPInput from "../../components/Inputs/OTPInput"
import GradientButton from "../../components/Buttons/GradientButton"
import commonActions from "../../actions/common"
import api from "../../api"
import useGlobalUserState from "../../stores/user"

const AuthModal = () => {
    const miscState = useGlobalMiscState()
    const userState = useGlobalUserState()
    const [modalStep, setModalStep] = useState("phone")
    const authPhoneNumber = miscState.get('authPhoneNumber')
    const meta = authModalMeta[modalStep]

    const updateMisc = (key, target = true) => (value) => {
        miscState.set(key, target ? value.target?.value : value)
    }

    const proceed =  async (value) => {
        if(modalStep === "phone") {
            const response = await commonActions.simpleGetter(api.usersApi.generateOTP, {
                otp: {
                    phone: '+' + authPhoneNumber.replace(/\D/g, "")
                }
            })
            if(response.id) {
                setModalStep("otp")
            }
        } else {
            const response = await commonActions.simpleGetter(api.usersApi.checkOTP, {
                otp: {
                    phone: '+' + authPhoneNumber.replace(/\D/g, ""),
                    code: value
                }
            })
            if(response.user) {
                const user = {
                    ...response.user,
                    token: response.token,
                    bonus: response.bonus
                }
                userState.setUser(user)
                updateMisc('authModalOpen')(false)
            }
        }
    }
    return (
        <div className="flex flex-col py-6 px-4">
            <p className="font-bold text-kilt-black text-xl">
                {meta.title}
            </p>
            <p className="text-info-gray mb-5">
                {meta.subtitle}
            </p>
            {modalStep === "phone" ? (
                <>
                    <ReactInputMask
                        className='bg-white rounded-xl px-4 py-2 border border-[#D9D9D9] mb-4'
                        mask="+7 (999) 999 99 99"
                        placeholder="+7 (___) ___ __ __"
                        value={authPhoneNumber}
                        onChange={updateMisc('authPhoneNumber')}
                        type="tel"
                    />
                    <GradientButton
                        text="Продолжить"
                        onClick={proceed}
                    />
                </>
            ) : (
                <OTPInput
                    length={4}
                    onComplete={proceed}
                />
            )}

        </div>
    )
}

export default AuthModal