import React, { forwardRef } from "react";
import closeWhite from '../../assets/close-white.svg'
import utils from "../../utils";
import { accessGlobalMiscState } from "../../stores/misc";

export const Photo = forwardRef(
  ({ image, index, faded, style, ...props }, ref) => {
    const inlineStyles = {
      opacity: faded ? "0.2" : "1",
      transformOrigin: "0 0",
      ...style,
    };

    const deleteImage = () => {
        const images = utils.shallowCopy(accessGlobalMiscState().get('addListingImages'))
        accessGlobalMiscState().set('addListingImages', images.filter(img => img.id !== image.id))
    }

    return (
        <div 
            className='col-span-6 lg:col-span-3 2xl:col-span-2 rounded-xl border border-kilt-border-gray flex items-center justify-center touch-none mb-2 mr-2 relative'
            ref={ref} 
            style={inlineStyles} 
            {...props}
        >
            <img
                className="w-32 h-32 object-cover rounded-xl"
                src={image?.base64}
            />
            <div 
                className="absolute top-[-10px] right-[-10px] z-10 rounded-3xl bg-red-600 cursor-pointer"
                onClick={deleteImage}
            >
                <img src={closeWhite} />
            </div>
        </div>
    )
  }
);
