const { useRef } = require("react");

const Modal = ({visible, alwaysVisible = false, setVisible, children, dimensions = "w-4/5 h-4/5"}) => {
    const modalRef = useRef(null);
    return (
      <>
        {(visible || alwaysVisible) ? (
          <div
            style={{
              display: alwaysVisible ? visible ? "flex" : "none" : "flex",
              direction: "row",
              top: 0,
              left: 0,
              width: "99vw",
              height: "100vh",
              zIndex: "100",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
            className="fixed"
            onClick={(e) => {
              if (modalRef.current.contains(e.target)) {
                return;
              }
              setVisible(false);
            }}
          >
            <div className={`bg-white ${dimensions}`} ref={modalRef}>
                {children}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
}

export default Modal