import { contactUsItems } from "../../constants"
import { useScreenSize } from "../../hooks/misc"

const ContactUs = ({}) => {
    const { width: screenWidth } = useScreenSize()
    const renderSingle = (item) => {
        return (
            <a className="flex flex-row items-center px-6 py-4 border-b border-b-[#DCDCDC]" target="_blank" {...( (!item.mobileOnly || (item.mobileOnly && screenWidth <= 640 )) ? { href: item.url } : {} )}>
                <img src={item.image} />
                <p className="ml-4 text-xl flex flex-col ">
                    <span className="text-xl">{item.label}</span>
                    {!!item.secondaryText && (
                        <span className="text-sm mt-1 opacity-80">
                            {item.secondaryText}
                        </span>
                    )}
                </p>
            </a>
        )
    }
    return (
        <div className="flex flex-col">
            {contactUsItems.map(item => renderSingle(item))}
        </div>
    )
}

export default ContactUs