import React from "react"
import { deleteAccountItems } from "../../constants"

export const DeleteAccountInfo = () => {
    return (
        <div className="xl:container flex flex-col px-4 sm:px-0">
            <h2 className="font-bold text-3xl mb-4">
                Как удалить аккаунт в Kilt?
            </h2>
            <div className="flex flex-col items-start">
                {deleteAccountItems.map((item) => (
                    <div className="my-4">
                        <p className="font-bold mb-5">
                            {item.label}
                        </p>
                        <img alt={item.label} src={item.image} />
                    </div>
                ))}
            </div>
            <h5 className="font-bold text-xl mt-2 mb-4">
                Какие данные будут удалены?
            </h5>
            <p className="mb-10">
                Вся информация, связанная с вашим аккаунтом, а также ваш бонусный аккаунт, ваши объявления и ваши оплаченные продвижения будут удалены в течение 24 часов.
            </p>
        </div>
    )
}