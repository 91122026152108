import { useEffect } from "react"
import { listingTypeMeta } from "../../constants"
import useGlobalConfigState from "../../stores/config"
import useGlobalSearchState from "../../stores/search"
import ListIconSelector from "./ListIconSelector"
import useGlobalAddState from "../../stores/add"

const ListingTypeSelect = ({ prop, type = "select", formType = "search", add = false }) => {
    const meta = listingTypeMeta[prop]
    const searchState = useGlobalSearchState()
    const addState = useGlobalAddState()
    const state = add ? addState : searchState
    const configState = useGlobalConfigState()
    const config = configState.get()
    const form = state.getValue('form')

    let filteredPt = []
    if (config && config.propertyTypes) {
        filteredPt = config.propertyTypes.filter(pt => {
            return pt.listing_type === form.listing_type
                &&
                !!config.listingStructures.find(ls => form.deal_type === ls.deal_type &&
                    pt.id === ls.property_type &&
                    pt.listing_type === ls.listing_type
                )
        })
    }

    const updateTypes = type => e => {
        state.mergeForm({
            [type]: parseInt(e.target.value)
        })
    }

    const listIconUpdate = (value) => {
        state.mergeForm({
            [prop]: value
        })
    }

    useEffect(() => {
        if (filteredPt?.length && !filteredPt.map((p) => p.id).includes(form.property_type)) {
            state.mergeForm({
                property_type: filteredPt[0].id
            })
        }
    }, [form.deal_type, form.listing_type, form.property_type])
    console.log((meta.list === "filtered" ? filteredPt : meta.list))
    console.log(filteredPt)
    return type === "select" ? (
        <select
            onChange={updateTypes(prop)}
            value={form[prop]}
            className="py-3 px-4 pe-9 block w-full bg-white text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        >
            {[...(meta.list === "filtered" ? filteredPt : formType in meta.list ? meta.list[formType] : meta.list)].map(t => (
                <option key={t[meta.valueKey]} value={t[meta.valueKey]}>{t[meta.labelKey]}</option>
            ))}
        </select>
    ) : (
        <ListIconSelector
            prop={prop}
            value={form[prop]}
            values={config.propertyTypes}
            setValue={listIconUpdate}
            type={type === "select" ? "vertical" : "horizontal"}
        />
    )
}

export default ListingTypeSelect