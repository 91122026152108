const KiltInput = ({value, onChange, type = 'text', additionalClass = ''}) => {
    return (
        <input 
            className={`border-[1.5px] border-touchable bg-white rounded-xl px-4 py-2 ${additionalClass}`} 
            value={value}
            type={type}
            onChange={(e) => onChange(e.target.value)}
        />
    )
}

export default KiltInput