import api from "../api"
import utils from "../utils";

const commonActions = {
    simpleGetter: async (apiFunction, params = {}) => {
        try {
            const res = await apiFunction(params)
            return utils.returnOrError(res.data)
        } catch(e) {
            console.log(e)
            return {}
        }
    }
}

export default commonActions