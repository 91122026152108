import logo from './logo.png'
import heart from './heart.svg'
import bell from './bell.svg'
import plus from './plus.svg'
import profile from './profile.svg'
import { Outlet, useNavigate } from 'react-router-dom'
import useGlobalUserState from '../../stores/user'
import { useGlobalMiscState } from '../../stores/misc'
import Modal from '../../components/Modal/Modal'
import ReactInputMask from 'react-input-mask'
import AuthModal from '../Auth/AuthModal'
import { useEffect } from 'react'
import Snackbar from '../../components/Snackbar/Snackbar'
const Root = ({}) => {
    const navigate = useNavigate()
    const userState = useGlobalUserState()
    const miscState = useGlobalMiscState()
    const authModalOpen = miscState.get('authModalOpen')
    const windowScroll = miscState.get('windowScroll')
    const user = userState.getUniversal('user')
    const protectedNavigate = (route) => () => {
        if(user.id) {
            navigate(route)
        } else {
            miscState.set('authModalOpen', true)
        }
    }
    const updateMisc = (key) => (value) => {
        miscState.set(key, value.target?.value || value)
    }

    const rootOnScroll = (e) => {
        miscState.set('windowScroll', window.scrollY)
    }

    useEffect(() => {
        document.addEventListener("scroll", rootOnScroll);

        return () => {
            document.removeEventListener("scroll", rootOnScroll);
        };
    }, []);

    return (
        <div>
            <div className={`lg:container pt-2.5 pb-4 px-2 xl:px-0 ${window.location.pathname.startsWith('/search') && windowScroll > 0 ? 'hidden' : ''}`}>
                <div className={`flex flex-row justify-between`}>
                    <img src={logo} className="w-[82px] h-[55px] cursor-pointer" onClick={() => navigate('/')} />
                    <div className="flex flex-row items-center">
                        <img src={heart} className="w-6 h-6 mr-5 hidden xl:block" />
                        <img src={bell} className="w-6 h-6 mr-5 hidden xl:block" />
                        <div className="flex flex-row items-center mr-5 cursor-pointer" onClick={protectedNavigate('/add-listing')}>
                            <img src={plus} className='w-5 h-5 mr-2' />
                            <span className="text-[#3244E4] font-bold">
                                Добавить объявление
                            </span>
                        </div>
                        <div className="cursor-pointer" onClick={protectedNavigate('/profile')}>
                            <img src={profile} className="w-11 h-11" />
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
            <Modal dimensions="w-full h-full xl:w-1/4 xl:h-auto rounded-xl" visible={authModalOpen} setVisible={updateMisc('authModalOpen')}>
                <AuthModal />
            </Modal>
            <Snackbar />
        </div>   
    )
}

export default Root