import { useEffect } from "react"
import useGlobalKatoState from "../stores/kato"
import commonActions from "../actions/common"
import api from "../api"
import useGlobalAddKatoState from "../stores/add-kato"

const useKato = (parent, type = 'search') => {
    const seachKatoState = useGlobalKatoState()
    const addKatoState = useGlobalAddKatoState()
    const katoState = type === 'search' ? seachKatoState : addKatoState
    const list = katoState.getValue('list')
    useEffect(() => {
        const fetchData = async () => {
            if (!(parent in list) && parent !== -1) {
                const response = await commonActions.simpleGetter(api.universalApi.find, { model: 'Kato', body: { filters: { parent_id: parent } } })
                if (response.list) {
                    katoState.mergeList({
                        [parent]: response.list.filter(i => {
                            if (parent === 0) {
                                return parseInt(i.id) >= 710000000 || parseInt(i.id) === 190000000
                            }
                            return true;
                        })
                    })
                }
            }
        }
        fetchData()
    }, [parent])
}

const useResidential = (parent, type = 'search') => {
    const seachKatoState = useGlobalKatoState()
    const addKatoState = useGlobalAddKatoState()
    const katoState = type === 'search' ? seachKatoState : addKatoState
    const resComplexesList = katoState.getValue('resComplexesList')
    useEffect(() => {
        const fetchData = async () => {
            if (!(parent in resComplexesList) && parent?.length) {
                const response = await commonActions.simpleGetter(api.residentialComplexApi.getResComplexes, parent)
                if (response.list) {
                    katoState.mergeValue('resComplexesList', {
                        [parent]: response.list
                    })
                }
            }
        }
        fetchData()
    }, [parent])
}

export {
    useKato,
    useResidential
}