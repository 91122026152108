import { useNavigate, useParams } from "react-router-dom"
import { personalMeta, profileTabs } from "../../constants"
import useGlobalUserState from "../../stores/user"
import chevronRight from "../../assets/chevron-right.svg"
import UserCard from "../../components/User/UserCard"
import MyListings from "./MyListings"
import ProfileList from "../../components/List/ProfileList"
import SearchResult from "../Search/SearchResult"
import BorderedButton from "../../components/Buttons/BorderedButton"
import api from "../../api"
import RentBooking from "../RentBooking/RentBooking"
import ContactUs from "./ContactUs"
import { ListingOptions } from "./ListingOptions"
import { ListingStats } from "../Listing/ListingStats"

const Profile = ({}) => {
    const { tab: paramTab } = useParams()
    const navigate = useNavigate()
    const userState = useGlobalUserState()
    const user = userState.get('user')
    console.log(profileTabs)
    const navigateTo = (tab) => () => {
        navigate(`/profile/${tab}`)
    }

    const logOut = () => {
        userState.logOut()
        navigate('/')
    }

    const renderTab = (tab) => {
        switch(tab.tabId) {
            case 'personal':
                return (
                    <UserCard
                        user={user}
                        active={paramTab === tab.tabId}
                        onClick={navigateTo(tab.tabId)}
                    />
                )
            case 'privacy-policy':
            case 'rules':
                return (
                    <a className={`flex flex-row px-4 py-5 rounded-xl justify-between cursor-pointer`} target="_blank" href={tab.url}>
                        <p className="font-semibold">{tab.label}</p>
                        <img src={chevronRight} />
                    </a>
                )
            case 'logout':
                return (
                    <div 
                        className={`flex flex-row ${paramTab === tab.tabId ? 'bg-touchable' : 'bg-transparent'} px-4 py-5 rounded-xl justify-between cursor-pointer text-[#F44336]`} 
                        onClick={logOut}
                    >
                        <p className="font-semibold">{tab.label}</p>
                    </div>
                )
            default:
                return (
                    <div className={`flex flex-row ${paramTab === tab.tabId ? 'bg-touchable' : 'bg-transparent'} px-4 py-5 rounded-xl justify-between cursor-pointer`} onClick={navigateTo(tab.tabId)}>
                        <p className="font-semibold">{tab.label}</p>
                        <img src={chevronRight} />
                    </div>
                )
        }
    }

    const renderTabInfo = (tabId) => {
        switch(tabId) {
            case 'personal':
                const renderKeyValue = (k, v) => (
                    <div className="flex flex-row">
                        <p className="text-kilt-muted flex-1">
                            {k}
                        </p>
                        <p className="font-bold text-typography flex-1">
                            {v}
                        </p>
                    </div>
                )
                return (
                    <div className="shadow-container p-5 bg-white rounded-xl">
                        <p className="font-bold text-2xl text-typography mb-4">
                            Информация
                        </p>
                        <div className="flex flex-col w-1/2">
                            {personalMeta.map(item => renderKeyValue(item.label, user[item.prop] || ''))}
                        </div>
                    </div>
                )
            case 'my-listings':
                return (
                    <ProfileList
                        type="listings"
                        itemRender={(item, params) => {
                            const listingOption = params.selectedOption
                            return (
                                <div className={`col-span-12 relative`}>
                                    <ListingOptions listing={item} />
                                    <div className={listingOption.additionalClassName || ""}>
                                        <SearchResult 
                                            listing={item} 
                                            parent="MyListings" 
                                            disabled={!!listingOption.disabled} 
                                            additionalClassName="mb-2"
                                        />
                                    </div>
                                    {item.status === 1 && (
                                        <div className="px-4 xl:px-10">
                                            <ListingStats listing={item} />
                                        </div>
                                    )}
                                </div>
                            )
                        }}
                    />
                )
            case 'my-contracts':
                return (
                    <ProfileList
                        type="contracts"
                        itemRender={(item, params) => {
                            return (
                                <div className="col-span-7 flex flex-col rounded-xl p-4 my-2.5 shadow-container">
                                    <div className="flex flex-row justify-between">
                                        <p className="font-semibold">
                                            Договор №{item.id}
                                        </p>
                                        <div className={`flex flex-row rounded-lg px-1.5 py-0.5 bg-${params.selectedOption?.color}`}>
                                            <p className="font-bold text-xs">
                                                {params.selectedOption?.displayLabel}  
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col mb-2.5">
                                        <p className="text-sm text-[#8794A5]">
                                            Дата заключения:
                                        </p>
                                        <p className="text-sm text-[#8794A5]">
                                            {new Date(item.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                    {!!item.document_name && (
                                        <a 
                                            className="text-primary border border-primary font-bold py-2.5 px-6 rounded-xl flex flex-row items-center justify-center"
                                            href={`${api.serverUrl}/deals/${item.document_name}.pdf`}
                                            target="_blank"
                                        >
                                            Подробнее
                                        </a>
                                            
                                    )}
                                </div>
                            )
                        }}
                    />
                )
            case 'my-rent-bookings': 
                return (
                    <ProfileList
                        type="rent-bookings"
                        itemRender={(item, params) => {
                            return (
                                <RentBooking
                                    item={item}
                                    params={params}
                                    additionalClassname="col-span-7"
                                />
                            )
                        }}
                    />
                )
            case 'my-notifications':
                return (
                    <ProfileList
                        type="notifications"
                        itemRender={(item, params) => {
                            return (
                                <div className="col-span-7 shadow-container rounded px-4 py-6 flex flex-col my-2">
                                    <p className="font-bold text-typography">
                                        {item.title}
                                    </p>
                                    <p className="text-sm text-typography">
                                        {item.content}
                                    </p>
                                </div>
                            )
                        }}
                    />
                )
            case 'blog':
                return (
                    <ProfileList
                        type="blog"
                        itemRender={(item, params) => {
                            return (
                                <div className={`bg-touchable px-5 py-2 flex flex-row mx-3 my-2 rounded-xl col-span-7 cursor-pointer`} onClick={() => navigate('/blog/1')}>
                                    <div className="w-9/12 pt-3 pb-5">
                                        <p className="text-sm text-typography opacity-50">
                                            {item.createdAt}
                                        </p>
                                        <p className="text-xl font-bold text-typography min-h-14">
                                            {item.title}
                                        </p>
                                    </div>
                                    <div className="w-3/12 bg-cover bg-center rounded-xl" style={{backgroundImage: `url(${api.serverUrl}/uploads/${item.image})`}}>
                                    </div>
                                </div>
                            )
                        }}
                    />
                )
            case 'contact-us':
                return (
                    <ContactUs />
                )
        }
    }

    return (
        <div className="xl:container grid grid-cols-12">
            <div className="col-span-3 flex flex-col">
                {profileTabs.map(t => renderTab(t))}
            </div>
            <div className="col-span-9 flex flex-col px-[10px]">
                {renderTabInfo(paramTab)}
            </div>
        </div>
    )
}

export default Profile