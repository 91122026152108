import { useEffect, useState } from "react"
import commonActions from "../actions/common"
import api from "../api"
import useGlobalUserState from "../stores/user"

const useListing = (id) => {
    const [listing, setListing] = useState({})
    useEffect(() => {
        setListing({})
        const fetch = async () => {
            const response = await commonActions.simpleGetter(api.listingApi.getListing, id)
            if(response.listing) {
                setListing(response.listing)
            }
        }
        fetch()
    }, [id])

    return listing
}

const useSimilar = (listing, showSimilar) => {
    const [list, setList] = useState([])
    useEffect(() => {
        if(showSimilar) {
            setList([])
            const fetch = async () => {
                const response = await commonActions.simpleGetter(api.listingApi.getSimilar, { id: listing.id, body: { rent_period: listing.rent_period } })
                if(response.listings) {
                    setList(response.listings)
                }
            }
            if(listing.id) {
                fetch()
            }
        }
    }, [listing, showSimilar])

    return list
}

const useCampaignData = () => {
    const [list, setList] = useState([])
    const userState = useGlobalUserState()
    const user = userState.get('user')

    const fetchData = async () => {
        const response = await commonActions.simpleGetter(api.campaignDataApi.getAll, user.id)
        if(response.campaign_data) {
            setList(response.campaign_data.map(campaign => {
                campaign.price = Math.floor(campaign.price * ((100 - response.discount) / 100))
                return campaign
            }))
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return list
}

export {
    useListing,
    useSimilar,
    useCampaignData
}