import { useRef, useState } from "react"
import { ListingOption } from "./ListingOption"
import threeDots from '../../assets/three-dots.svg'
import { useClickOutside } from "../../hooks/misc"

export const ListingOptions = ({listing}) => {
    const optionsWrapperRef = useRef(null)
    const [open, setOpen] = useState(false)
    useClickOutside(optionsWrapperRef, () => setOpen(false))
    const options = []
    if(listing.status === 0) {
        options.push(...['publish'])
    } else if(listing.status === 1) {
        options.push(...['promote', 'edit', 'sendToArchive'])
    } else if([2,3].includes(listing.status)) {
        options.push(...['edit'])
    }

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            <div 
                className="shadow-container bg-white absolute left-11 z-50 top-6 cursor-pointer rounded-xl px-[12px] py-[6px] flex flex-row items-center justify-center"
                onClick={toggleOpen}
            >
                <img src={threeDots} />
            </div>
            {open && (
                <div
                    className="shadow-container bg-white absolute flex flex-col rounded-md py-2 left-11 z-50 top-6 w-52
                    "
                    ref={optionsWrapperRef}
                >
                    {options.map((o, i) => (
                        <div className={`px-3 py-0.5 ${i !== options.length - 1 ? 'border-b border-b-[#DCDCDC]' : ''}`}>
                            <ListingOption
                                type={o}
                                listing={listing}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}