import api from "../../api"

const BlogSingle = ({}) => {
    return (
        <div className="lg:container grid grid-cols-12">
            <div className="col-span-12">
                <img 
                    className="w-full max-h-[40vh] object-cover rounded-2xl"
                    src={`${api.serverUrl}/uploads/blog_1.png`}
                />
            </div>
            <div className="col-span-12 px-4 lg:px-0 my-4">
                <p className="text-sm">
                    6 июля, 2022 года
                </p>
                <p className="font-bold text-2xl">
                    Когда в присоединённых районах Алматы появится качественная вода
                </p>
                <p className="mt-6">
                    Проблема качественного водоснабжения в присоединённых районах Алматы постепенно решается. До конца 2022 года власти обещают сдать в эксплуатацию новый водозабор, который обеспечит центральным водоснабжением жителей нескольких микрорайонов. {'\n'}
                    По заверению акима города Ерболата Досаева, в этом году завершится строительство станции на р. Каргалы. Качественная вода поступит в дома мкр Карагайлы, Каргалы, Курамыс, Тастыбулак и Таусамалы.{'\n'}
                    Также в этом году начнут строить станцию на р. Аксай и завершат разработку ПСД водозабора Ерменсай.
                </p>
            </div>
            <div className="col-span-12 px-4 lg:px-0">
                <img 
                    className="w-full max-h-[50vh] object-cover rounded-2xl"
                    src={`${api.serverUrl}/uploads/blog_4.png`}
                />
            </div>
            <div className="col-span-12 px-4 lg:px-0 my-4">
                Запуск объектов обеспечит центральным водоснабжением более 150 тысяч жителей.{'\n'}
                Кроме того, продолжается строительство водопровода (в этом году планируется сдать более 371 км) и канализации (более 260 км).{'\n'}
                Параллельно решается вопрос с электросетями: строится две электроподстанции. В этом году запустят одну из них — «Алмагуль». Запуск второй намечен на июль 2023 года. Также до конца года модернизируют 138 км электросетей.
            </div>
        </div>
    )
}

export default BlogSingle