import { listIcons } from "../../constants"

const classes = {
    vertical: "flex flex-row gap-y-4 flex-wrap mt-5 mb-5 overflow-x-scroll pb-2",
    horizontal: "flex flex-row gap-y-4 mt-2 mb-2 overflow-x-scroll pb-2"
}

const ListIconSelector = ({prop, values, value, setValue, type = "vertical"}) => {
    if(listIcons[prop]) {
        return (
            <div className={classes[type]}>
                {values && values.map((item, ix) => {
                    const Icon = listIcons[prop][item.id]
                    return type === "vertical" ? (
                        <div
                            className={`flex flex-col items-center cursor-pointer ${ix === values?.length - 1 ? 'mr-8' : 'mr-4'}`}
                            onClick={() => setValue(item.id)}
                        >
                            <div className={`rounded-xl p-5 border border-touchable ${value === item.id ? 'list-icon-circle-selected' : 'bg-transparent'}`}>
                                <Icon />
                            </div>
                            <span className="mt-1 text-typography text-xs max-w-[78px]">
                                {item.label_ru}
                            </span>
                        </div>
                    ) : (
                        <div 
                            className={`${item.id === 6 ? 'min-w-[50vw]' : 'min-w-[44vw]'} ${value === item.id ? 'bg-primary text-white list-icon-selected' : 'bg-touchable list-icon-60 text-kilt-black'} py-6 px-4 rounded-xl grid grid-cols-12 items-center cursor-pointer ${ix === values?.length - 1 ? 'mr-8' : 'mr-4'}`}
                            onClick={() => setValue(item.id)}
                        >
                            <div className="col-span-2">
                                <Icon />
                            </div>
                            <span className="col-span-10 pl-4 font-bold">
                                {item.label_ru}
                            </span>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default ListIconSelector