import { useEffect, useState } from "react"
import { useGlobalMiscState } from "../../stores/misc"
import useGlobalUserState from "../../stores/user"
import commonActions from "../../actions/common"
import api from "../../api"
import LoadingWrapper from "../../components/Loading/LoadingWrapper"

const WithdrawHistory = () => {
    const miscState = useGlobalMiscState()
    const userState = useGlobalUserState()
    const [list, setList] = useState([])
    const user = userState.get('user')
    const loading = miscState.get('loading')

    const getHistory = async () => {
        miscState.set('loading', true)
        const response = await commonActions.simpleGetter(api.universalApi.find, {
            model: 'BonusTransaction',
            body: {
                filters: {
                    partner_id: user.id
                }
            }
        })
        if(response.list?.length) {
            const bonusesResp = await commonActions.simpleGetter(api.universalApi.find, {
                model: 'Bonus',
                body: {
                    filters: {
                        id: response.list.map(item => item.bonus_id)
                    }
                }
            })
            if(bonusesResp.list?.length) {
                const bonuses = bonusesResp.list 
                for(let item of response.list) {
                    const found = bonuses.find(b => b.id === item.bonus_id)
                    if(!!found) {
                        item.phone_number = found.phone_number
                    }
                }
            }
            setList(response.list)
        }
        miscState.set('loading', false)
    }

    useEffect(() => {
        getHistory()
    }, [])

    return (
        <div className="flex flex-row w-full items-center justify-center">
            <LoadingWrapper loading={loading}>
                {list.length ? (
                    <div className="grid grid-cols-12 w-full mt-6 pl-4">
                        <div className="col-span-12 grid grid-cols-12 border-b border-b-[#DCDCDC] mb-2">
                            <div className="col-span-2">
                                ID транзакции
                            </div>
                            <div className="col-span-2">
                                Номер телефона
                            </div>
                            <div className="col-span-3">
                                Сумма
                            </div>
                            <div className="col-span-3">
                                Дата
                            </div>
                        </div>
                        {list.map(item => (
                            <div className="col-span-12 grid grid-cols-12 border-b border-b-[#DCDCDC] mb-2">
                                <div className="col-span-2">
                                    {item.id}
                                </div>
                                <div className="col-span-2">
                                    {item.phone_number || ""}
                                </div>
                                <div className="col-span-3">
                                    {item.difference}
                                </div>
                                <div className="col-span-3">
                                    {new Date(item.createdAt).toLocaleString()}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-lg font-bold text-kilt-black">
                        Транзакций не найдено
                    </p>
                )}
            </LoadingWrapper>
        </div>
    )
}

export default WithdrawHistory