import { useRef, useState } from "react";
import useGlobalConfigState from "../../stores/config"
import useGlobalSearchState from "../../stores/search"
import FilterItem from "./FilterItem";
import GradientButton from "../../components/Buttons/GradientButton";
import { updateResults } from "../../actions/search";
import { useClickOutside, useOutsideAlerter } from "../../hooks/misc";
import { quickFiltersMeta } from "../../constants";

const QuickFilter = ({ type, label }) => {
    const [visible, setVisible] = useState(false)
    const modalRef = useRef(null);
    useClickOutside(modalRef, () => setVisible(false))
    const searchState = useGlobalSearchState()
    const quickFilterMeta = quickFiltersMeta[type] || {}
    const configState = useGlobalConfigState()
    const config = configState.get()
    const form = searchState.getValue('form')
    const filterType = config.propLabels?.find(pl => pl.property === type)?.filter_type || ''
    const searchFilter = () => {
        setVisible(false)
        updateResults()
    }
    return (
        <div className='quick-filter-wrapper'>
            <div className="bg-kilt-gray rounded-xl py-[5px] px-4 xl:py-2 xl:px-2.5 mr-2 cursor-pointer whitespace-nowrap" onClick={() => setVisible(!visible)}>
                {label}
            </div>
            {visible ? (
              <div className={`bg-white fixed xl:absolute rounded-xl px-4 py-5 z-10 shadow-container flex flex-col w-full quick-filter-container xl:min-w-[30vw] left-0`} ref={modalRef}>
                <p className="font-bold text-lg text-typography">
                    {config.propLabels.find(pl => pl.property === type)?.label_ru || ''}
                </p>
                <div className={`${quickFilterMeta.additionalClass || ""}`}>
                    <FilterItem type={type} />
                </div>
                <GradientButton
                    onClick={searchFilter}
                    text="Применить"
                />
              </div>
          ) : null}
        </div>
      );
}

export default QuickFilter