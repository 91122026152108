import { useEffect, useState } from "react"
import useGlobalSearchState from "../stores/search"
import commonActions from "../actions/common"
import api from "../api"
import useGlobalConfigState from "../stores/config"

const useResultsCount = () => {
    const searchState = useGlobalSearchState()
    const configState = useGlobalConfigState()
    const config = configState.get()
    const searchStateSelf = searchState.getState()
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    const getCount = async() => {
			setLoading(true)
			const body = {
					filters: {},
					config: {},
					source: 'web'
			}
			const form = searchState.getValue('form')
			for (let finalKey of Object.keys(form).filter(
					(key) =>
						form[key] !== '' &&
						(!(form[key] instanceof Array) ||
							(form[key] instanceof Array && form[key]?.length > 0))
			)) {
				if (finalKey === 'price') {
					const obj = {};
					for (let key of Object.keys(form[finalKey])) {
						obj[key] = form[finalKey][key].replace(/ /g, '');
					}
					body.filters[finalKey] = obj;
				} else {
					body.filters[finalKey] = form[finalKey];
				}
			}
			const configKeys = Object.keys(form).filter(
					(key) => !['deal_type', 'listing_type', 'property_type'].includes(key)
			);
			for(let cKey of configKeys) {
					body.config[cKey] = config.propLabels.find(pl => pl.property === cKey)?.filter_type || ''
			}
			const resp = await commonActions.simpleGetter(api.listingApi.getResultsCount, body)
			setLoading(false)
			if('count' in resp) {
					setCount(resp.count)
			} else {
					setCount(0)
			}
    }

    useEffect(() => {
        getCount()
    }, [searchStateSelf.form])

    return { loading, count }
}

export {
    useResultsCount
}