import {hookstate, useHookstate} from '@hookstate/core';
const store = hookstate({
    loading: false,
    images: [],
    form: {
        deal_type: 2,
        listing_type: 1,
        property_type: 1
    },
    errors: [],
    yPositions: {}
})

const wrapState = (state) => ({
    setUniversal: (prop, value) => {
        state[prop].set(value)
    },
    getUniversal: (prop) => state[prop].value,
    getValue: (prop) => state[prop].value,
    updateValue: (key, value) => {
        state[key].set(value)
    },
    mergeValue: (key, value) => {
        state[key].merge(value)
    }, 
    mergeForm: (value) => {
        state.form.merge(value)
    }
})

const useGlobalAddState = () => wrapState(useHookstate(store))

export default useGlobalAddState
export const accessGlobalAddState = () => wrapState(store)
