import { useEffect, useState } from "react";
import useGlobalAddState from "../../stores/add";
import useGlobalConfigState from "../../stores/config";
import useGlobalUserState from "../../stores/user";
import AddListingItem from "./AddListingItem";
import DraggableGrid from "../../components/DraggableGrid/DraggableGrid";
import DraggableGridLayout from "../../components/DraggableGrid/DraggableGridLayout";
import { useScreenSize } from "../../hooks/misc";
import utils from "../../utils";
import { addImagesNumOfBlocks } from "../../constants";
import ImageGallery from "./ImageGallery";
import AddImage from "./AddImage";
import GradientButton from "../../components/Buttons/GradientButton";
import { useGlobalMiscState } from "../../stores/misc";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import listingActions from "../../actions/listing";
import { useNavigate, useParams } from "react-router-dom";
import useGlobalAddKatoState from "../../stores/add-kato";
import addActions from "../../actions/add";
import axios from "axios";
import api from "../../api";

const AddListing = ({ mode = "add" }) => {
  const isEdit = mode === "edit";
  const { id: listingId } = useParams();
  const addState = useGlobalAddState();
  const addKatoState = useGlobalAddKatoState();
  const userState = useGlobalUserState();
  const configState = useGlobalConfigState();
  const miscState = useGlobalMiscState();
  const navigate = useNavigate();
  const form = addState.getUniversal("form");
  const user = userState.get("user");
  const config = configState.get("config");
  const loading = miscState.get("loading");
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (!!listingId) {
      listingActions.getEditListing(listingId);
    }
  }, [listingId]);

  const addListing = async () => {
    const errorFields = addActions.checkRequiredFields();
    if (errorFields.length) {
      window.scrollTo({
        top: errorFields[0].yPosition,
        behavior: "smooth",
      });
    } else {
      miscState.set("loading", true);
      const result = await listingActions.add(isEdit);
      miscState.set("loading", false);
      if (result) {
        utils.showSnackbar(
          "Ваше объявление успешно сохранено и будет опубликовано после проверки модератором",
          "success"
        );
        miscState.set("addListingImages", []);
        addKatoState.reset();
        navigate("/profile/my-listings");
      } else {
        utils.showSnackbar(
          "Что-то пошло не так. Пожалуйста, повторите попытку позднее"
        );
      }
    }
  };

  useEffect(() => {
    if (config && config.listingStructures && !isEdit) {
      const structure = config.listingStructures.find((ls) => {
        return (
          form.deal_type === ls.deal_type &&
          form.listing_type === ls.listing_type &&
          form.property_type === ls.property_type
        );
      });
      if (structure) {
        const addList = structure.add.split(",");
        const obj = {};
        for (let prop of addList) {
          obj[prop] = "";
        }
        const initialState = {
          seller_name: user.firstname || "",
          seller_phone_number: user.phone || "",
          seller_whatsapp: user.phone || "",
          seller_telegram: "",
          lat: "",
          lng: "",
        };
        console.log({
          ...obj,
          ...initialState,
        });
        addState.setUniversal("form", {
          deal_type: form.deal_type,
          listing_type: form.listing_type,
          property_type: form.property_type,
          ...obj,
          ...initialState,
        });
      }
    }
  }, [form.deal_type, form.listing_type, form.property_type]);

  const loadData = async () => {
    const req = await axios.get(
      api.serverUrl + "/api/v1/users/get-data/" + user.id
    );
    if (req?.data?.is_blocked === true) {
      setIsBlocked(true);
    }
  };

  useEffect(() => {
    if (user) {
      loadData();
    }
  }, []);

  if (isBlocked) {
    return (
      <div className='text-center'>
        <h1>Ваш аккаунт заблокирован</h1>
        <p
          className='text-center'
          style={{
            maxWidth: "400px",
            margin: "auto",
            marginTop: "20px",
            color: "#666",
          }}
        >
          Вы получили блокировку за нарушение правил пользования сервисом.
        </p>
      </div>
    );
  }

  return (
    <div className='lg:container grid grid-cols-12 px-4'>
      {Object.keys(form).map((k) => (
        <AddListingItem prop={k} />
      ))}
      <div className='col-span-12 md:col-span-6 md:pr-10 py-5'>
        <AddImage />
      </div>
      <div className='col-span-12 md:col-span-6 py-5'>
        <ImageGallery />
      </div>
      <div className='col-span-12 mb-10 text-center'>
        <LoadingWrapper loading={loading} size='small'>
          <GradientButton
            text='Опубликовать'
            onClick={addListing}
            additionalClass='w-full md:w-1/6'
          />
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default AddListing;
