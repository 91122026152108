const ListingKeyValue = ({label, value, valueRender}) => {
    return (
        <div className="flex flex-row py-2.5">
            <p className="flex-1 text-kilt-muted">
                {label}
            </p>
            {!!valueRender ? valueRender() : ( 
                <p className="flex-1 text-lg text-typography">
                    {value}
                </p>
            )}
        </div>
    )
}

export default ListingKeyValue