import {hookstate, useHookstate} from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

const store = hookstate({
    user: {
        local: true
    }
}, localstored({
    key: 'kilt-user-store',
    engine: localStorage,
    initializer: () => {
        return new Promise((resolve, reject) => {
            resolve({
                user: {
                    local: false
                }
            })
        })
    }
}))

const wrapState = (state) => ({
    get: () => state.user.value,
    setUser: (user) => {
        state.user.merge(user)
    },
    getUniversal: (key) => state[key].value,
    setFCMToken: (token) => {
        state.user.set({ fcmToken: token })
    },
    mergeFCMToken: (token) => {
        state.user.merge({ fcmToken: token })
    },
    setBonus: (bonus) => {
        state.user.merge({bonus})
    },
    logOut: () => {
        state.user.set(old => ({ fcmToken: old.fcmToken }))
    }
})

const useGlobalUserState = () => wrapState(useHookstate(store))

export default useGlobalUserState
export const accessGlobalUserState = () => wrapState(store)