import { useEffect, useState } from "react"
import commonActions from "../../actions/common"
import api from "../../api"
import useGlobalUserState from "../../stores/user"
import LoadingWrapper from "../../components/Loading/LoadingWrapper"
import utils from "../../utils"
import { rentBookingStatusMapper } from "../../constants"
import BorderedButton from "../../components/Buttons/BorderedButton"
import { useNavigate } from "react-router-dom"

const RentBooking = ({item, params, additionalClassname}) => {
    const isMyRent = params.selectedOption?.label === "Сдаю"
    const userState = useGlobalUserState()
    const navigate = useNavigate()
    const [listing, setListing] = useState({})
    const [user, setUser] = useState(isMyRent ? {} : {...userState.get()})
    const [loading, setLoading] = useState(false)

    const getListing = async () => {
        setLoading(true)
        if(isMyRent) {
            const userRes = await commonActions.simpleGetter(api.universalApi.find, {
                model: 'User',
                body: {
                    filters: {
                        id: item.user_id
                    }
                }
            })
            if(userRes.list?.length) {
                setUser(userRes.list[0])
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if(!!item.listing_id && !!item.user_id) {
            getListing()
        }
    }, [])
    return (
        <div className={`shadow-container rounded-xl bg-transparent border border-[#DBDFE4] mt-3 mb-4 pb-3 ${additionalClassname}`}>
            <div className="flex flex-row border-b border-b-gray-300 py-2 justify-center">
                <p className="font-bold">
                    Бронирование №{item.id}
                </p>
            </div>
            <LoadingWrapper loading={loading}>
                <div className="flex flex-col px-4 pt-2">
                    {
                        [
                            ['Номер телефона', user.phone],
                            ['Дата заезда', new Date(item.date_from).toLocaleDateString('ru-RU', { month: 'long', day: 'numeric' })],
                            ['Дата выезда', new Date(item.date_to).toLocaleDateString('ru-RU', { month: 'long', day: 'numeric' })],
                            ['Общая стоимость', utils.formatPrice(item.total) + ' тг' ],
                            ['Дата создания', new Date(item.createdAt).toLocaleTimeString('ru-RU', { month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' }) ]
                        ].map(item => (
                            <div className="flex flex-col">
                                <p className="text-xl font-bold">
                                    {item[0]}
                                </p>
                                <p>
                                    {item[1]}
                                </p>
                            </div>
                        ))
                    }
                    <div className="flex flex-row mt-2 mb-4">
                        <span className="text-lg font-bold mr-1">
                            Статус:
                        </span>
                        <span className="text-lg">
                            {rentBookingStatusMapper[item.status]}
                        </span>
                    </div>
                    <BorderedButton
                        text="Посмотреть объявление"
                        onClick={() => navigate(`/listings/${item.listing_id}`)}
                    />
                </div>
            </LoadingWrapper>
        </div>
    )
}

export default RentBooking