import api from "../api"
import { editBlackList, listingEventFilter, mandatoryAddListingFields } from "../constants"
import { accessGlobalAddState } from "../stores/add"
import { accessGlobalConfigState } from "../stores/config"
import { accessGlobalMiscState } from "../stores/misc"
import { accessGlobalUserState } from "../stores/user"
import utils from "../utils"
import commonActions from "./common"

const listingActions = {
    add: async (isEdit) => {
        const addState = accessGlobalAddState()
        const userState = accessGlobalUserState()
        const miscState = accessGlobalMiscState()
        const addListingImages = miscState.get('addListingImages')
        const user = userState.get('user')
        const form = addState.getUniversal('form')
        const body = {}
        for(let key of Object.keys(form)) {
            if(form[key] != '') {
                body[key] = utils.formatBody(key, form[key])
            }
        }
        body.status = 2
        body.seller_id = user.id
        const apiFunction = api.listingApi[isEdit ? 'editListing' : 'addListing']
        const addResponse = await commonActions.simpleGetter(apiFunction, body)
        if(addResponse?.id) {
            if(addListingImages?.length) {
                if(isEdit) {
                    await listingActions.updateExistingImages()
                }
                const formData = new FormData()
                for(let imageIdx in addListingImages) {
                    const image = addListingImages[imageIdx]
                    if(image.existing) {
                        continue
                    }
                    formData.append('images', image.file, `${addResponse?.id}-${parseInt(imageIdx) + 1}.${image.file.name.split('.')[1] || 'jpg'}`)
                }
                if(Boolean(addListingImages.find(img => !img.existing))) {
                    const imagesResponse = await commonActions.simpleGetter(api.listingApi.addImages, formData)
                    if(imagesResponse.success) {
                        return true
                    }
                } else {
                    return true
                }
            } else {
                return true
            }
        }
        return false
    },
    updateExistingImages: async () => {
        const addState = accessGlobalAddState()
        const miscState = accessGlobalMiscState()
        const addListingImages = miscState.get('addListingImages')
        const form = addState.getUniversal('form')
        const editImagesBody = {
            existingImages: [],
            listing_id: form.id
        }

        for(let imageIdx in addListingImages) {
            const img = addListingImages[imageIdx]

            if(img.existing) {
                editImagesBody.existingImages.push({
                    id: img.id,
                    key: img.id,
                    order: parseInt(imageIdx) + 1
                })
            }
        }
        await commonActions.simpleGetter(api.listingApi.updateImages, editImagesBody)
    },
    getListingStats: async (list) => {
        const userState = accessGlobalUserState()
        const user = userState.get('user')
        const listingIds = list.map(l => l.id)
        const eventsResponse = await commonActions.simpleGetter(api.universalApi.find, 
            { 
                model: 'Event', 
                body: { 
                    filters: { 
                        listing_id: listingIds
                    }
                }
            }
        )
        if(eventsResponse.list) {
            for(let listing of list) {
                for(let event of Object.keys(listingEventFilter)) {
                    const meta = listingEventFilter[event]
                    listing[`stats_${event}`] = eventsResponse.list.filter(e => e.user_id !== user.id && e.name?.includes(meta.search)).length
                }
            }
        }
        return list
    },
    getEditListing: async (id) => {
        const configState = accessGlobalConfigState()
        const addState = accessGlobalAddState()
        const miscState = accessGlobalMiscState()
        const config = configState.get()
        const response = await commonActions.simpleGetter(api.listingApi.getListing, id)
        if(Boolean(response.listing)) {
            const listing = response.listing
            const setObj = {}
            const structure = config.listingStructures.find(ls => {
                return listing.deal_type === ls.deal_type &&
                listing.listing_type === ls.listing_type &&
                listing.property_type === ls.property_type
            })
            if(structure) {
                const addList = structure.add.split(',')
                const structureKeys = [...new Set([...addList, ...mandatoryAddListingFields])].filter(k => !editBlackList.includes(k))
                for(let k of structureKeys) {
                    setObj[k] = listing[k]
                    if(config.propLabels.find(pl => pl.property === k)?.add_type === "range" && !!listing[k]) {
                        setObj[k] = '' + listing[k]
                    }
                    if(!listing[k]) {
                        setObj[k] = ''
                    }
                }
                setObj.id = listing.id
                addState.setUniversal('form', setObj)
                if(listing.images?.length) {
                    const existingImages = listing.images.map(img => ({
                        base64: utils.getSingleImageLink(img),
                        id: img.id,
                        existing: true
                    }))
                    miscState.set('addListingImages', existingImages)
                }
            }
        }
    }
}

export default listingActions