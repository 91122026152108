import { accessGlobalSearchState } from "../stores/search"

const updateResults = (resetPage = true) => {
    if(resetPage) {
        accessGlobalSearchState().setResults([])
        accessGlobalSearchState().setUniversal('offset', 0)
    }
    accessGlobalSearchState().setUniversal('counter', accessGlobalSearchState().getUniversal('counter') + 1)
}

export {
    updateResults
}