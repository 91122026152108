import LightGallery from 'lightgallery/react';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

import { useCallback, useEffect, useRef } from 'react';

const ListingGallery = ({images, openCounter, index}) => {
    const lightGallery = useRef(null);

    const openGallery = useCallback(() => {
        lightGallery.current.openGallery();
    }, []);
    
    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    useEffect(() => {
        if(openCounter > 0) {
            openGallery()
        }
    }, [openCounter])

    return (
        <LightGallery
            elementClassNames="custom-classname"
            dynamic
            index={index}
            dynamicEl={images}
            onInit={onInit}
            plugins={[lgZoom, lgVideo]}
        />
    )
}

export default ListingGallery