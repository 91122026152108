import { hookstate, useHookstate } from "@hookstate/core";
import { localstored } from "@hookstate/localstored";

// Persisted Store (will be saved to localStorage)
const persistedStore = hookstate(
  {
    form: {
      deal_type: 2,
      listing_type: 1,
      property_type: 1,
      status: 1,
      user_type: "",
    },
    sorting: "new",
  },
  localstored({
    key: "search-store-16-08-24",
    engine: localStorage,
  })
);

// Non-Persisted Store (will not be saved to localStorage)
const nonPersistedStore = hookstate({
  results: [],
  loading: false,
  countLoading: false,
  map: [],
  lastUpdatePage: Date.now(),
  resultsCount: 0,
  offset: 0,
  counter: 0,
  total: 0,
});

// Wrapper function to handle both stores
const wrapState = (persistedState, nonPersistedState) => ({
  getState: () => ({ ...persistedState, ...nonPersistedState }),
  getResults: () => nonPersistedState.results.value,
  setResults: (res) => {
    nonPersistedState.results.set(res);
  },
  addResults: (res) => {
    nonPersistedState.results.merge(res);
  },
  setMapResults: (res) => {
    nonPersistedState.map.set(res);
  },
  getMapResults: () => nonPersistedState.map.value,
  getLoading: () => nonPersistedState.loading.value,
  setLoading: (value) => {
    nonPersistedState.loading.set(value);
  },
  getCountLoading: () => nonPersistedState.countLoading.value,
  setCountLoading: (value) => {
    nonPersistedState.countLoading.set(value);
  },
  getValue: (key) =>
    key in persistedState
      ? persistedState[key].value
      : nonPersistedState[key].value,
  updateValue: (key, value) => {
    if (key in persistedState) {
      persistedState[key].set(value);
    } else {
      nonPersistedState[key].set(value);
    }
  },
  nextPage: (updatedTime) => {
    if (updatedTime - nonPersistedState.lastUpdatePage.value < 1000) return;
    nonPersistedState.offset.set((p) => p + 1);
    nonPersistedState.lastUpdatePage.set(updatedTime);
  },
  mergeForm: (value) => {
    persistedState.form.merge(value);
  },
  clear: () => {
    nonPersistedState.offset.set(0);
    nonPersistedState.counter.set(0);
    nonPersistedState.total.set(0);
  },
  getUniversal: (prop) => {
    if (prop in persistedState) {
      return persistedState[prop].value;
    } else if (prop in nonPersistedState) {
      return nonPersistedState[prop].value;
    }
    return undefined;
  },
  setUniversal: (prop, value) => {
    if (prop in persistedState) {
      persistedState[prop].set(value);
    } else if (prop in nonPersistedState) {
      nonPersistedState[prop].set(value);
    }
  },
});

const useGlobalSearchState = () =>
  wrapState(useHookstate(persistedStore), useHookstate(nonPersistedStore));

export default useGlobalSearchState;
export const accessGlobalSearchState = () =>
  wrapState(persistedStore, nonPersistedStore);
