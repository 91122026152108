import areaIcon from '../screens/Search/sqrft.svg'
import numRoomsIcon from '../screens/Search/room.svg'
import numFloorsIcon from '../screens/Search/building.svg'

const icons = {
    area: numRoomsIcon,
    floor: numFloorsIcon,
    num_rooms: areaIcon
}

const renderHeaderItem = (key, val, val2) => {
    let render
    switch (key) {
        case 'num_rooms':
            render = (
                <span className="text-info-gray">{val} комн</span>
            )
            break
        case 'area':
            render = (
                <p className="text-info-gray">
                    <span>{val} м<sup>2</sup></span>
                </p>
            )
            break
        case 'floor':
            render = (
                <span className="text-info-gray">этаж {val}{val2 ? `/${val2}` : ''}</span>
            )
            break
        default:
            render = null;
    }
    return render
}

const renderHeader = (listing, headerStructure) => {
    if (!headerStructure) {
        return null
    }
    return headerStructure.map(prop => {
        if (listing[prop]) {
            return (
                <div className="flex flex-row mr-4 items-center" key={prop}>
                    <img alt="prop" className="mr-[2px]" src={icons[prop]} />
                    {prop === 'floor' ? renderHeaderItem(prop, listing[prop], listing.num_floors) : renderHeaderItem(prop, listing[prop])}
                </div>
            )
        }
        return null;
    })
}

export {
    renderHeader
}