import { useGlobalMiscState } from "../../stores/misc"
import close from '../../assets/close.svg'
import { BonusFullWidth } from "../../components/Bonus/BonusFullWidth"
import { useCampaignData } from "../../hooks/listing"
import { useState } from "react"
import { CampaignSingle } from "../../components/Campaign/CampaignSingle"
import GradientButton from "../../components/Buttons/GradientButton"
import { campaignActions } from "../../actions/campaign"

export const PromoteModal = () => {
    const miscState = useGlobalMiscState()
    const campaigns = useCampaignData()
    const [campaignSelected, setCampaignSelected] = useState({})
    console.log(campaigns)

    const closeModal = () => {
        miscState.set('optionPromoteModalOpen', false)
    }

    const setSelectedCampaign = (c) => () => {
        setCampaignSelected(c)
    }

    const createPromotion = async () => {
        await campaignActions.createCampaign(campaignSelected)
    }

    return (
        <div className='flex flex-col relative'>
            <div className="flex flex-row justify-between pt-5 px-4">
                <img src={close} onClick={closeModal} className="cursor-pointer" />
                <p className="font-bold text-typography">
                     Продвижение в поиске
                </p>
                <p className='text-kilt-dark-gray'></p>
            </div>
            <div className="flex flex-col px-4">
                <BonusFullWidth additionalClassName="my-4" />
                {campaigns.map(c => (
                    <CampaignSingle
                        campaign={c}
                        isSelected={campaignSelected.id === c.id}
                        setSelected={setSelectedCampaign}
                    />
                ))}
                <div className="flex flex-row my-5 py-3 rounded-xl bg-touchable px-4 justify-between">
                    <span className="text-lg font-medium">
                        К оплате
                    </span>
                    <span className="text-lg font-bold">
                        {campaignSelected.price} ₸
                    </span>
                </div>
                <GradientButton
                    text="Оплатить и разместить"
                    onClick={createPromotion}
                    additionalClass="mb-4"
                />
            </div>
        </div>
    )
}