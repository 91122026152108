const svgIcons = {
    Commercial: (props) => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1801_28056)">
        <g clip-path="url(#clip1_1801_28056)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5257 2.14935C13.8205 2.33156 14 2.65342 14 3V6.46482L19.5547 10.168C19.8329 10.3534 20 10.6656 20 11V20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20H4V7C4 6.62123 4.214 6.27497 4.55279 6.10557L12.5528 2.10557C12.8628 1.95058 13.2309 1.96714 13.5257 2.14935ZM6 20H12V7.01368C11.9999 7.00395 11.9999 6.99422 12 6.98449V4.61803L6 7.61803V20ZM14 8.86852V20H18V11.5352L14 8.86852ZM9 8C9.55228 8 10 8.44772 10 9V9.01C10 9.56229 9.55228 10.01 9 10.01C8.44772 10.01 8 9.56229 8 9.01V9C8 8.44772 8.44772 8 9 8ZM9 11C9.55228 11 10 11.4477 10 12V12.01C10 12.5623 9.55228 13.01 9 13.01C8.44772 13.01 8 12.5623 8 12.01V12C8 11.4477 8.44772 11 9 11ZM9 14C9.55228 14 10 14.4477 10 15V15.01C10 15.5623 9.55228 16.01 9 16.01C8.44772 16.01 8 15.5623 8 15.01V15C8 14.4477 8.44772 14 9 14ZM9 17C9.55228 17 10 17.4477 10 18V18.01C10 18.5623 9.55228 19.01 9 19.01C8.44772 19.01 8 18.5623 8 18.01V18C8 17.4477 8.44772 17 9 17Z" fill="#010101"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_1801_28056">
        <rect width="24" height="24" fill="#010101"/>
        </clipPath>
        <clipPath id="clip1_1801_28056">
        <rect width="24" height="24" fill="#010101"/>
        </clipPath>
        </defs>
        </svg>
    ),
    Flat: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={22}
          height={22}
          fill="none"
          {...props}
        >
          <path
            fill="#010101"
            className="fill-path"
            d="M6.417 12.833a.917.917 0 0 1-.917.917h-.917a.917.917 0 0 1 0-1.833H5.5a.916.916 0 0 1 .917.916Zm3.666-.916h-.916a.916.916 0 1 0 0 1.833h.916a.917.917 0 0 0 0-1.833ZM5.5 15.583h-.917a.917.917 0 0 0 0 1.834H5.5a.917.917 0 0 0 0-1.834Zm4.583 0h-.916a.917.917 0 0 0 0 1.834h.916a.917.917 0 0 0 0-1.834Zm-4.583-11h-.917a.917.917 0 0 0 0 1.834H5.5a.917.917 0 0 0 0-1.834Zm4.583 0h-.916a.917.917 0 1 0 0 1.834h.916a.917.917 0 0 0 0-1.834ZM5.5 8.25h-.917a.917.917 0 0 0 0 1.833H5.5a.917.917 0 1 0 0-1.833Zm4.583 0h-.916a.917.917 0 1 0 0 1.833h.916a.917.917 0 1 0 0-1.833ZM22 9.167v8.25A4.59 4.59 0 0 1 17.417 22H4.583A4.59 4.59 0 0 1 0 17.417V4.583A4.589 4.589 0 0 1 4.583 0h5.5a4.589 4.589 0 0 1 4.584 4.583h2.75A4.589 4.589 0 0 1 22 9.167Zm-17.417 11h8.25V4.583a2.75 2.75 0 0 0-2.75-2.75h-5.5a2.75 2.75 0 0 0-2.75 2.75v12.834a2.75 2.75 0 0 0 2.75 2.75Zm15.584-11a2.75 2.75 0 0 0-2.75-2.75h-2.75v13.75h2.75a2.75 2.75 0 0 0 2.75-2.75v-8.25Zm-2.75 2.75a.916.916 0 1 0 0 1.832.916.916 0 0 0 0-1.832Zm0 3.666a.917.917 0 1 0 0 1.834.917.917 0 0 0 0-1.834Zm0-7.333a.917.917 0 1 0 0 1.833.917.917 0 0 0 0-1.833Z"
          />
        </svg>
    ),
    Home: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={22}
          height={22}
          fill="none"
          viewBox="0 0 24 22"
          {...props}
        >
          <path
            stroke="#010101"
            className="stroke-path"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m20.49 16.109.007-7.724L15 2.905a4.252 4.252 0 0 0-6 0L3.515 8.37v7.734a4.236 4.236 0 0 0 4.242 4.228h8.49a4.236 4.236 0 0 0 4.242-4.224Z"
            clipRule="evenodd"
          />
          <path
            fill="#010101"
            className="fill-path"
            d="M21.499 3.023a1 1 0 1 0-2 0h2Zm-1 5.362h-1a1 1 0 0 0 .294.708l.706-.708Zm1.462 2.87a1 1 0 0 0 1.412-1.417l-1.412 1.416ZM4.221 9.08A1 1 0 0 0 2.81 7.663L4.221 9.08Zm-3.593.758a1 1 0 1 0 1.412 1.416L.628 9.838Zm13.04 10.495a1 1 0 1 0 2 0h-2Zm-5.334 0a1 1 0 1 0 2 0h-2Zm11.165-17.31v5.362h2V3.023h-2Zm.294 6.07 2.168 2.161 1.412-1.416-2.168-2.161-1.412 1.416ZM2.809 7.663.628 9.838l1.412 1.416L4.221 9.08 2.81 7.663Zm12.858 12.67v-2.374h-2v2.374h2Zm0-2.374a3.667 3.667 0 0 0-3.666-3.666v2c.92 0 1.666.746 1.666 1.666h2Zm-3.666-3.666a3.667 3.667 0 0 0-3.667 3.666h2c0-.92.746-1.666 1.667-1.666v-2Zm-3.667 3.666v2.374h2v-2.374h-2Z"
          />
        </svg>
    )
}

export default svgIcons