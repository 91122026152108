const Switch = ({value, setValue, val1, val2, label1, label2, ...rest}) => {
    return (
        <div className="flex flex-row my-0 xl:my-4 mx-4 rounded-xl bg-touchable">
            <button 
                className={`flex-1 rounded-[10px] text-sm font-bold px-2 py-2.5 ${value === val1 ? 'mr-[-4px] z-10 text-white bg-primary' : 'mr-0 z-1 text-[#6B6D79] bg-transparent'}`}
                onClick={() => setValue(val1)}
            >
                {label1 || val1}
            </button>
            <button 
                className={`flex-1 rounded-[10px] text-sm font-bold px-2 py-2.5 ${value === val2 ? 'ml-[-4px] z-10 text-white bg-primary' : 'mr-0 z-1 text-[#6B6D79] bg-transparent'}`}
                onClick={() => setValue(val2)}
            >
                {label2 || val2}
            </button>
        </div>
    )
}

export default Switch