import { useEffect, useState } from "react"
import { calculatorConfig } from "../../constants"
import KiltInput from "../../components/Inputs/KiltInput"
import utils from "../../utils"

const CalculatorInput = ({formKey, value, onChange}) => {
    const meta = calculatorConfig[formKey]
    if(meta) {
        return (
            <div className={`flex flex-col mb-2.5 col-span-${meta.colSpan} ${meta.additionalClass || ""}`}>
                <p className="text-typography mb-2.5">
                    {meta.label}
                </p>
                <KiltInput
                    value={value}
                    onChange={onChange}
                    additionalClass="font-bold text-xl text-typography"
                />
            </div>
        )
    }
}
const ListingCalculator = ({listing}) => {
    const [form, setForm] = useState({
        totalCost: '',
        initialFee: '',
        percentage: '16',
        term: '240'
    })
    const onFieldChange = (key) => (value) => {
        if(!isNaN(parseInt(value.replace(/ /g, ''))) || value == '') {
            setForm({
                ...form,
                [key]: utils.formatPrice(parseInt(value.replace(/ /g, ''))) || ""
            })
        }
    }
    useEffect(() => {
        if(listing.price) {
            setForm({
                ...form,
                totalCost: utils.formatPrice(parseInt(listing.price + '')),
                initialFee: utils.formatPrice(parseInt(parseInt(listing.price / 10) + ''))
            })
        }
    }, [listing])
    if(listing.deal_type === 2) {
        const initial = utils.calculateInitialPercentage(form.initialFee, form.totalCost)
        const monthly = utils.calculateMonthly(form)
        console.log(initial, monthly)
        return (
            <div className="shadow-container bg-white flex flex-col p-5 rounded-xl">
                <p className="text-2xl text-typography font-bold mb-5">
                    Рассчитайте ежемесячный платёж по ипотеке
                </p>
                <div className="grid grid-cols-12">
                    {Object.keys(form).map(k => (
                        <CalculatorInput
                            formKey={k}
                            value={form[k]}
                            onChange={onFieldChange(k)}
                        />
                    ))}
                    <div className="col-span-12">
                        <p className="text-typography mb-1">
                            Ежемесячный платёж
                        </p>
                        <p className="text-primary font-bold text-3xl">
                            {monthly} ₸
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListingCalculator