import {hookstate, useHookstate} from '@hookstate/core';

const store = hookstate({
    authModalOpen: false,
    authPhoneNumber: '',
    disposable: '',
    windowScroll: 0,
    loading: false,
    addListingImages: [],
    profileListFetchTrigger: 0,
    optionSelectedListingId: 0,
    optionPromoteModalOpen: false
})

const wrapState = (state) => ({
    get: (prop) => state[prop].value,
    set: (prop, value) => state[prop].set(value),
    add: (prop, value) => state[prop].merge(value)
})

export const useGlobalMiscState = () => wrapState(useHookstate(store))
export const accessGlobalMiscState = () => wrapState(store)
