import chevronRight from "../../assets/chevron-right.svg"

const UserCard = ({user, active, onClick = () => {}, additionalClassname = ''}) => {
    if(!user.id) {
        return null
    }
    return (
        <div className={`flex flex-row ${active ? 'bg-touchable' : 'bg-transparent'} px-4 py-6 rounded-xl justify-between cursor-pointer ${additionalClassname}`} onClick={onClick}>
            <div className="flex flex-col">
                {user.firstname && user.lastname && (
                    <p className="font-bold text-xl">{user.firstname} {user.lastname}</p>
                )}
                <p className="font-semibold">{user.phone}</p>
            </div>
            <img src={chevronRight} />
        </div>
    )
}

export default UserCard