import { addListingMeta } from "../../constants"
import useGlobalAddState from "../../stores/add"
import useGlobalConfigState from "../../stores/config"
import search from '../../assets/search.svg'
import chevronRight from '../../assets/chevron-right.svg'
import { useEffect, useState } from "react"
import AddressSelector from "../../components/Address/AddressSelector"
import { MapModal } from "./MapModal"
import useGlobalAddKatoState from "../../stores/add-kato"
import utils from "../../utils"

export const AddressModal = ({ prop }) => {
    const addState = useGlobalAddState()
    const addKatoState = useGlobalAddKatoState()
    const configState = useGlobalConfigState()
    const currentResidentialSelected = addKatoState.getValue('currentResidentialSelected')
    const resComplexesList = addKatoState.getValue('resComplexesList')
    const form = addState.getUniversal('form')
    const errors = addState.getUniversal('errors')
    const config = configState.get('config')
    const meta = addListingMeta.find(m => m.props.includes(prop)) || addListingMeta.find(m => m.default)
    const configItem = config.propLabels.find(pl => pl.property === prop) || {}
    const label = configItem.label_ru || meta.labels?.[prop] || ''
    const addTypeConfig = configItem.add_type || ''
    const addType = meta.customAddType || addTypeConfig
    const hasError = errors.includes(prop)

    const [modalOpen, setModalOpen] = useState(false)

    const toggleModal = () => {
        setModalOpen(!modalOpen)
        addState.setUniversal('errors', errors.filter(k => k !== prop))
    }

    useEffect(() => {
        if (form.address_string?.length && !currentResidentialSelected.length) {
            const foundResidential = Object.values(resComplexesList).reduce((acc, curr) => [...acc, ...curr], []).find((rc) => rc.address_string === form.address_string);
            if (foundResidential) {
                addState.mergeForm({
                    address_string: ''
                })
            }
        }
    }, [resComplexesList, currentResidentialSelected, form.address_string])

    const renderField = () => {
        switch (prop) {
            case 'kato_path':
                return (
                    <AddressSelector
                        type="add"
                        initialViewMode="district"
                        customFieldRender={(titleRender) => (
                            <div className={`rounded-xl py-3 px-4 flex flex-row justify-between items-center border-[1.5px] ${hasError ? 'border-kilt-error' : 'border-[#D9D9D9]'} w-full md:w-1/2 cursor-pointer`} onClick={toggleModal}>
                                <p className={`text-sm leading-5 ${titleRender?.length ? 'line-clamp-1 font-semibold text-kilt-black' : 'text-kilt-dark-gray'}`}>
                                    {titleRender?.length ? titleRender.map(item => item.name || item.residential_complex_name || "").join(' / ') : 'Город, район'}
                                </p>
                                <img src={search} />
                            </div>
                        )}
                    />
                )
            case 'residential_complex_name':
                return (
                    <AddressSelector
                        type="add"
                        initialViewMode="residential"
                        customFieldRender={(titleRender) => (
                            <div className={`rounded-xl py-3 px-4 flex flex-row justify-between items-center border-[1.5px] ${hasError ? 'border-kilt-error' : 'border-[#D9D9D9]'} w-full md:w-1/2 cursor-pointer`} onClick={toggleModal}>
                                <p className={`text-sm leading-5 ${titleRender?.length ? 'line-clamp-1 font-semibold text-kilt-black' : 'text-kilt-dark-gray'}`}>
                                    {titleRender?.length ? titleRender.map(item => item.name || item.residential_complex_name || "").join(' / ') : 'Жилой комплекс'}
                                </p>
                                <img src={chevronRight} />
                            </div>
                        )}
                    />
                )
            case 'map':
                return (
                    <MapModal />
                )
            default:
                return null
        }
    }

    return (
        <>
            {renderField()}
        </>
    )
}