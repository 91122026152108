import { listingEventFilter } from "../../constants"
import useGlobalConfigState from "../../stores/config"

export const ListingStats = ({ listing }) => {
    const configState = useGlobalConfigState()
    const config = configState.get()
    const advancedConfig = config.advanced || {}
    const publishedAtEnabledRaw = advancedConfig.published_at_enabled || "[]"
    const publishedAtEnabled = JSON.parse(publishedAtEnabledRaw)
    console.log(publishedAtEnabled)
    const showActiveUntil = listing.status === 1 &&
        !!publishedAtEnabled.find(p => listing.deal_type === p[0] && listing.listing_type === p[1] && listing.property_type === p[2])
    const activeUntilRaw = new Date(listing.publishedAt || listing.createdAt)
    activeUntilRaw.setDate(activeUntilRaw.getDate() + 7)
    const activeUntil = activeUntilRaw.toLocaleDateString('ru-RU', { month: 'long', day: 'numeric' })

    return (
        <div className="flex flex-col border-b border-b-[#E8E8E8] rounded mt-2 p-2 pb-4">
            {showActiveUntil && (
                <div className="flex flex-col mb-2">
                    <p className="text-lg font-bold text-typography">
                        Активно до
                    </p>
                    <p>
                        {activeUntil}
                    </p>
                </div>
            )}
            <div className="flex flex-col mb-2">
                <p className="text-lg font-bold text-typography">
                    Опубликовано
                </p>
                <p>
                    {new Date(listing.publishedAt || listing.createdAt).toLocaleDateString('ru-RU', { month: 'long', day: 'numeric' })}
                </p>
            </div>
            <p className="text-lg font-bold text-typography mb-3">
                Статистика
            </p>
            <div className="flex flex-row">
                {Object.keys(listingEventFilter).map(k => {
                    return (
                        <div className="flex flex-row mb-3 items-center flex-1">
                            <div className="flex-[2]">
                                <img src={listingEventFilter[k].icon} />
                            </div>
                            <p className="flex-[4]">
                                {listing[`stats_${k}`]}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}