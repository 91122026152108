import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from 'react'
import Modal from '../../components/Modal/Modal'
import { useScreenSize } from '../../hooks/misc';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, { Marker } from 'react-map-gl';
import bgMap from '../../assets/map.png'
import GradientButton from '../../components/Buttons/GradientButton';
import commonActions from '../../actions/common';
import useGlobalAddState from '../../stores/add';
import useGlobalAddKatoState from '../../stores/add-kato';
import utils from '../../utils';

export const MapModal = ({}) => {
    const addState = useGlobalAddState()
    const katoState = useGlobalAddKatoState()
    const currentKatoSelected = katoState.getValue('currentKatoSelected')
    let katoLatLng = {}
    console.log(currentKatoSelected)
    if(currentKatoSelected.length) {
        katoLatLng = utils.getLatLngFromKato(currentKatoSelected[0].parent_id)
    }
    const form = addState.getUniversal('form')
    const mapRef = useRef(null)
    const [modalOpen, setModalOpen] = useState(false)
    const { width: screenWidth, height: screenHeight } = useScreenSize()

    const onMapClick = async (e) => {
        addState.mergeForm({
            lat: e.lngLat?.lat,
            lng: e.lngLat?.lng
        })
        try {
            const responseRaw = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=3e792c10-6d3f-499a-a6c5-cadd3df9924b&geocode=${e.lngLat?.lng},${e.lngLat?.lat}&format=json`)
            const response = await responseRaw.json()
            if(response?.response?.GeoObjectCollection?.featureMember?.length) {
                const members = response?.response?.GeoObjectCollection?.featureMember.filter(m => ["house", "district", "street"].includes(m.GeoObject?.metaDataProperty?.GeocoderMetaData?.kind))
                if(members?.length) {
                    addState.mergeForm({
                        address_string: members[0].GeoObject?.name
                    })          
                }
            }
        } catch(e) {
            
        }
    }

    const flyTo = (lng, lat, zoom = null) => {
        mapRef.current?.flyTo({
            center: [lng, lat],
            speed: 0.9,
            zoom: zoom || mapRef.current?.getZoom()
        })
    }

    useEffect(() => {
        if(!!form.lat && !!form.lng) {
            flyTo(form.lng, form.lat)
        }
    }, [form.lat, form.lng])

    useEffect(() => {
        console.log(katoLatLng)
        if(!!katoLatLng.lat && !!katoLatLng.lng) {
            flyTo(katoLatLng.lng, katoLatLng.lat, 13)
        }
    }, [katoLatLng.lat, katoLatLng.lng])

    useEffect(() => {
        mapRef.current?.resize()
    }, [modalOpen])

    return (
        <>
            <div 
                className="w-full md:w-1/2 py-8 relative flex flex-row justify-center items-center rounded-xl bg-cover bg-no-repeat bg-center"style={{
                    backgroundImage: `url(${bgMap})`
                }} 
                onClick={() => setModalOpen(true)}
            >
                <GradientButton
                    text="Выбрать на карте"
                    onClick={() => setModalOpen(true)}
                    additionalClass="z-50"
                />
                <div className="w-full h-full absolute rounded-xl backdrop-blur-sm" />
            </div>
            <Modal alwaysVisible dimensions="w-full h-full xl:w-3/5 xl:h-3/5 overflow-y-scroll relative flex flex-col justify-end" visible={modalOpen} setVisible={setModalOpen}>
                <div className="absolute w-full flex flex-col px-4 z-10">
                        <div className="bg-white rounded-lg shadow-container mb-2 py-2 flex flex-row items-center justify-center">
                            <p className="text-kilt-black font-semibold line-clamp-1">
                                {!!form.lat && !!form.lng && !!form.address_string ? form.address_string : 'Нажмите на точку на карте для выбора адреса'}
                            </p>
                        </div>
                        {!!form.lat && !!form.lng && !!form.address_string && (
                            <GradientButton
                                text="Выбрать этот адрес"
                                onClick={() => setModalOpen(false)}
                                additionalClass="self-center mb-2 w-3/4"
                            />
                        )}
                </div>
                <Map
                    ref={mapRef}
                    mapboxAccessToken='pk.eyJ1Ijoicnlzc2Jla292IiwiYSI6ImNsb2ZuNHUydzB0cnkya3Fwd2FvNmJ0MHQifQ.PpGBbqrGlZK2VuWwvKQSQg'
                    initialViewState={{
                        longitude: form.lng || 76.917284,
                        latitude: form.lat || 43.23922,
                        zoom: 14
                    }}
                    style={{
                        width: screenWidth * 0.6 * 0.985,
                        height: screenHeight * 0.6 * 0.999
                    }}
                    onClick={onMapClick}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                >
                    {!!form.lat && !!form.lng && (
                        <Marker
                            longitude={form.lng}
                            latitude={form.lat}
                            anchor="bottom"
                        >
                            <img 
                                className="w-[25px] h-[41px]"
                                src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png"
                            />
                        </Marker>
                    )}
                </Map>
            </Modal>
        </>
    )
}