import { useEffect } from "react"
import { useGlobalMiscState } from "../../stores/misc"

const Snackbar = () => {
    const miscState = useGlobalMiscState()
    const open = miscState.get('snackbarOpen')
    const severity = miscState.get('snackbarSeverity')
    const content = miscState.get('snackbarContent')

    useEffect(() => {
        if(open) {
            setTimeout(() => {
                miscState.set('snackbarOpen', false)
            }, 5000)
        }
    }, [open])

    return (
        <div className="fixed flex flex-row items-center justify-center top-6 left-2/4 right-auto -translate-x-1/2 min-w-72 z-[1000]">
            <div className={`kilt-snackbar ${open ? 'kilt-snackbar-active' : ''} kilt-snackbar-${severity} py-2 px-6 rounded-md`}>
                <p className="text-white">
                    {content}
                </p>
            </div>
        </div>
    )
}

export default Snackbar