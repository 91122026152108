import { allRequiredFields } from "../constants"
import { accessGlobalAddState } from "../stores/add"
import { accessGlobalConfigState } from "../stores/config"

const addActions = {
    checkRequiredFields: () => {
        const addState = accessGlobalAddState()
        const configState = accessGlobalConfigState()
        const form = addState.getUniversal('form')
        const config = configState.get('config')
        const yPositions = addState.getUniversal('yPositions')
        const structure = config.listingStructures.find(ls => {
            return form.deal_type === ls.deal_type &&
            form.listing_type === ls.listing_type &&
            form.property_type === ls.property_type
        })
        const errors = []
        if(structure && structure.required) {
            const structureRequiredFields = structure.required.split(',');
            const requiredFields = [...structureRequiredFields, ...allRequiredFields];
            for(let key of Object.keys(form)) {
                if(form[key] == '' && requiredFields.includes(key)) {
                    errors.push(key)
                }
            }
        }
        addState.setUniversal('errors', errors)
        const finalList = errors.map((key) => ({
            prop: key,
            yPosition: yPositions[key]
        })).sort((a, b) => a.yPosition - b.yPosition)
        return finalList
    }
}

export default addActions