import React, {useState} from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { useScreenSize } from '../../hooks/misc';
import { useGlobalMiscState } from '../../stores/misc';
import { SortablePhoto } from './SortablePhoto';
import { Photo } from './Photo';
import utils from '../../utils';
import { arrayMoveImmutable } from 'array-move';

const DNDKit = () => {
    const { width: screenWidth } = useScreenSize()
    const miscState = useGlobalMiscState()
    const images = miscState.get('addListingImages')
    console.log(images)

    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor, {
        activationConstraint: {
            distance: 1
        }
    }), useSensor(TouchSensor, {
        activationConstraint: {
            distance: 1
        }
    }));
  
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={images} strategy={rectSortingStrategy}>
          <div className="grid grid-cols-12">
            {images.map((image, index) => (
              <SortablePhoto key={image.id} image={image} index={index} />
            ))}
            
          </div>
        </SortableContext>
      </DndContext>
    );
  
    function handleDragStart(event) {
      setActiveId(event.active.id);
    }
  
    function handleDragEnd(event) {
      const {active, over} = event;
  
      if (active.id !== over.id) {
        const oldIndex = images.map(img => img.id).indexOf(active.id);
        const newIndex = images.map(img => img.id).indexOf(over.id);
        miscState.set('addListingImages', arrayMoveImmutable(utils.arrayOfObjectsShallowCopy(images), oldIndex, newIndex))
      }
  
      setActiveId(null);
    }
  
    function handleDragCancel() {
      setActiveId(null);
    }
}

export default DNDKit