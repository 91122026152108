import { useState } from "react"
import GradientButton from "../../components/Buttons/GradientButton"
import commonActions from "../../actions/common"
import api from "../../api"
import { useGlobalMiscState } from "../../stores/misc"
import LoadingWrapper from "../../components/Loading/LoadingWrapper"
import UserCard from "../../components/User/UserCard"
import Modal from "../../components/Modal/Modal"
import OTPInput from "../../components/Inputs/OTPInput"
import useGlobalUserState from "../../stores/user"
import utils from "../../utils"
import ReactInputMask from "react-input-mask"
import { withdrawBonusesFields } from "../../constants"

const WithdrawBonuses = () => {
    const [withdrawUser, setWithdrawUser] = useState({})
    const [userPhone, setUserPhone] = useState('')
    const [total, setTotal] = useState('')
    const [amount, setAmount] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const miscState = useGlobalMiscState()
    const userState = useGlobalUserState()
    const user = userState.get('user')
    const loading = miscState.get('loading')

    const requestBody = {
        phone_number: withdrawUser.phone,
        action: 1,
        difference: parseInt(amount),
        partner_id: user.id,
        reason: 1,
        payload: {
            total: parseInt(total),
            source: 'partner_admin'
        }
    }

    const onInputChange = (func, key) => (e) => {
        const fieldMeta = withdrawBonusesFields.find(f => f.name === key) || {}
        func(utils.fieldTransform(e.target.value, fieldMeta.transform))
    }

    const search = async () => {
        miscState.set('loading', true)
        const response = await commonActions.simpleGetter(api.universalApi.find, {
            model: 'User',
            body: {
                filters: {
                    phone: `+${userPhone.replace(/\D/g, "")}`
                }
            }
        })
        miscState.set('loading', false)
        if(response.list?.length) {
            setWithdrawUser(response.list[0])
        } else {
            utils.showSnackbar('Пользователь не найден', 'error')
        }
    }

    const validateForm = () => {
        if(parseInt(total) < parseInt(amount)) {
            return { success: false, message: 'Сумма чека не может быть меньше суммы списания' }
        }
        return { success: true }
    }

    const showModal = async () => {
        const isValid = validateForm()
        if (!isValid.success) {
            utils.showSnackbar(isValid.message)
        } else {
            const response = await commonActions.simpleGetter(api.bonusApi.transaction, requestBody)
            if(response.message === "otp_required") {
                setModalOpen(true)
            } else if(response.message === "not_enough_funds") {
                utils.showSnackbar('Недостаточно средств для списания', 'error')
            }
        }
    }

    const finalize = async (value) => {
        const response = await commonActions.simpleGetter(api.bonusApi.transaction, 
            {
                ...requestBody,
                code: value
            }
        )
        if(response.msg === "success") {
            utils.showSnackbar('Успешно', 'success')
            setWithdrawUser({})
            setAmount('')
            setUserPhone('')
            setModalOpen(false)
        }
    }

    return (
        <div className="grid grid-cols-12 pl-4">
            <div className="col-span-4">
                <p className="text-kilt-black font-semibold text-lg">
                    Введите номер телефона
                </p>
                <ReactInputMask
                    className='bg-white rounded-xl px-4 py-2 border border-[#D9D9D9] mb-4'
                    mask="+7 (999) 999 99 99"
                    placeholder="+7 (___) ___ __ __"
                    value={userPhone}
                    onChange={onInputChange(setUserPhone, 'phone_number')}
                    type="tel"
                />
                <GradientButton
                    text="Найти"
                    onClick={search}
                />
            </div>
            <div className="col-span-6 flex flex-row items-center pr-4">
                <LoadingWrapper loading={loading && !!userPhone.length}>
                    <UserCard
                        user={withdrawUser}
                        active={true}
                        additionalClassname="w-full"
                    />
                </LoadingWrapper>
            </div>
            {!!withdrawUser.id && (
                <>
                    <div className="col-span-4 mt-6">
                        <p className="text-kilt-black font-semibold text-lg">
                            Введите сумму чека
                        </p>
                        <input 
                            className={'border border-[#D9D9D9] bg-white rounded-xl px-4 py-2 my-4'}
                            value={total}
                            onChange={onInputChange(setTotal, 'total')}
                        />
                    </div>
                    <div className="col-span-4 mt-6">
                        <p className="text-kilt-black font-semibold text-lg">
                            Введите сумму списания
                        </p>
                        <input 
                            className={'border border-[#D9D9D9] bg-white rounded-xl px-4 py-2 my-4'}
                            value={amount}
                            onChange={onInputChange(setAmount, 'amount')}
                        />
                        <GradientButton
                            text="Списать"
                            onClick={showModal}
                        />
                    </div>
                </>
            )}
            <Modal 
                dimensions="w-full h-full xl:w-1/4 xl:h-auto rounded-xl" 
                visible={modalOpen} 
                setVisible={setModalOpen}
            >
                <div className="flex flex-col py-6 px-4">
                    <p className="font-bold text-kilt-black text-xl mb-4">
                        Введите код из смс
                    </p>
                    <OTPInput
                        length={4}
                        onComplete={finalize}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default WithdrawBonuses