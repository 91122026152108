import {hookstate, useHookstate} from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

const store = hookstate({
    events: [],
    viewHistory: []
}, localstored({
    key: 'events-store',
    engine: localStorage
}))

const wrapState = (state) => ({
    getEvents: () => state.events.value,
    clear: () => state.events.set([]),
    add: (event) => state.events.merge(event),
    addView: (id) => {
        if(!state.viewHistory.value.includes(id)) {
            state.viewHistory.merge([id])
        }
    },
    getViewHistory: () => state.viewHistory.value
})
 
const useGlobalEventsState = () => wrapState(useHookstate(store))

export default useGlobalEventsState
export const accessGlobalEventsState = () => wrapState(store)
