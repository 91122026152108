import { useState } from "react"
import { blogMockData } from "../constants"

const useBlogItems = () => {
    const [data, setData] = useState(blogMockData)

    return data
}

export {
    useBlogItems
}