import { useEffect, useState } from "react"
import { myListingOptions, profileLists } from "../../constants"
import { useGlobalMiscState } from "../../stores/misc"
import useGlobalUserState from "../../stores/user"
import commonActions from "../../actions/common"
import api from "../../api"
import LoadingWrapper from "../../components/Loading/LoadingWrapper"
import { OrbitProgress } from "react-loading-indicators"
import listingActions from "../../actions/listing"

const ProfileList = ({type, itemRender}) => {
    const meta = profileLists[type] || profileLists.listings
    const miscState = useGlobalMiscState()
    const userState = useGlobalUserState()
    const [selected, setSelected] = useState(meta.initialOption)
    const [list, setList] = useState([])
    const user = userState.get('user')
    const loading = miscState.get('loading')
    const profileListFetchTrigger = miscState.get('profileListFetchTrigger')
    const selectedOption = meta.options.find(o => o[meta.optionSearchLabel] === selected) || {}

    const commonFilters = (requestFilter) => {
        switch(requestFilter.type) {
            case 'userId':
                return user.id
            case 'selectedOption':
                return selectedOption[requestFilter.selectedOptionField]
        }
    }

    const commonRenderParams = {
        options: meta.options,
        selectedOption: selectedOption
    }

    const getRenderParams = () => {
        const obj = {}
        for(let k of meta.passedParams) {
            obj[k] = commonRenderParams[k]
        }
        return obj
    }

    const updateSelected = (e) => {
        setSelected(e.target.value)
    }

    const getList = async () => {
        miscState.set('loading', true)
        const response = await commonActions.simpleGetter('customApi' in meta ? meta.customApi : api.universalApi.find,
            'customRequestParam' in meta ?
            commonFilters({ type: meta.customRequestParam })
            :
            {
                model: meta.requestModel,
                body: {
                    filters: meta.requestFilters.map(r => ({ [ 'customKeyGetter' in r ? r.customKeyGetter(selectedOption) : r.key]: commonFilters(r) })).reduce((acc, curr) => ({...acc, ...curr}), {}),
                    params: meta.requestParams
                }
            }
        )
        miscState.set('loading', false)
        const respKey = 'customResponseKey' in meta ? meta.customResponseKey : 'list'
        if(response[respKey]) {
            let finalList = response[respKey]
            if(type === "listings") {
                finalList = await listingActions.getListingStats(finalList)
            }
            setList(finalList)
        } else {
            setList([])
        }
    }

    useEffect(() => {
        setSelected(meta.initialOption)
    }, [type])

    useEffect(() => {
        if(user.id && 'label' in selectedOption && !('mockData' in meta)) {
            getList()
        }
    }, [selected, type, selectedOption, profileListFetchTrigger])

    const displayList = 'mockData' in meta ? meta.mockData : list

    return (
        <div className="grid grid-cols-12">
            {meta.showOptions && (
                <div className="col-span-6">
                    <select
                        onChange={updateSelected}
                        value={selected}
                        className="py-3 px-4 w-full bg-white border border-kilt-border-gray rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    >
                        {meta.options.map(t => (
                            <option key={t[meta.optionSearchLabel]} value={t[meta.optionSearchLabel]}>{t[meta.optionSearchLabel]}</option>
                        ))}
                    </select>
                </div>
            )}
            <LoadingWrapper loading={loading} render={(
                <div className="col-span-12 flex flex-row justify-center mt-4">
                    <OrbitProgress size="small" color="#3F4FE0" />
                </div>
            )}>
                {displayList.filter('listFilter' in meta ? meta.listFilter(selected) : () => true).map(l => itemRender(l, getRenderParams()))}
            </LoadingWrapper>
        </div>
    )
}

export default ProfileList