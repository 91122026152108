import ListingTypeSelect from '../../components/Selectors/ListingTypeSelect'
import FilterItem from '../Search/FilterItem'
import home from './home.png'
import GradientButton from '../../components/Buttons/GradientButton'
import { useNavigate } from 'react-router-dom'
import HomeBlog from '../Blog/HomeBlog'
import AddressSelector from '../../components/Address/AddressSelector'
const Home = ({}) => {
    const navigate = useNavigate()
    const search = () => {
        navigate('/search')
    }
    return (
        <>
            <div className="w-full bg-center" style={{ backgroundImage: `url(${home})` }}>
                <div className="lg:container pt-7 xl:pt-28 xl:pb-20 grid grid-cols-12">
                    <span className="text-white font-semibold text-2xl xl:text-5xl col-span-10 xl:col-span-6 mb-5 xl:mb-0 pl-4">
                        Подбери недвижимость вместе с KILT
                    </span>
                    <div className="hidden xl:flex xl:col-span-6" />
                    <div className="col-span-12 xl:col-span-4">
                        <FilterItem
                            type="deal_type"
                        />
                    </div>
                    <div className="col-span-12 ml-4 block xl:hidden">
                        <ListingTypeSelect prop="property_type" type="icons" />
                    </div>
                    <div className="col-span-12 grid-cols-12 bg-white rounded-xl px-4 py-2 mx-4 hidden xl:grid">
                        <div className="col-span-2">
                            <ListingTypeSelect prop="listing_type" />
                        </div>
                        <div className="col-span-2 pl-2">
                            <ListingTypeSelect prop="property_type" />
                        </div>
                        <div className="col-span-4 flex flex-row items-center pl-4">
                            <p className="text-typography mr-4">
                                Цена
                            </p>
                            <FilterItem
                                type="price"
                                margin={false}
                                border="border-[1px] border-[#D9D9D9]"
                            />
                        </div>
                        <AddressSelector
                            wrapperClassName='col-span-4 flex flex-row items-center justify-center cursor-pointer'
                        />
                    </div>
                    <div className="col-span-12 flex flex-row justify-end mx-4 mt-2 mb-7 xl:mb-0">
                        <GradientButton
                            text="Поиск"
                            onClick={search}
                            additionalClass="w-full xl:w-auto px-12"
                        />
                    </div>
                </div>
            </div>
            <div className="lg:container grid grid-cols-12">
                <HomeBlog />
            </div>
        </>
    )
}

export default Home