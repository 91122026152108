import { useState } from "react"
import { useGlobalMiscState } from "../../stores/misc"
import utils from "../../utils"
import { useScreenSize } from "../../hooks/misc"
import { addImagesNumOfBlocks } from "../../constants"
import DNDKit from "../../components/DraggableGrid/DNDKit"

const ImageGallery = ({}) => {
    const miscState = useGlobalMiscState()
    const images = miscState.get('addListingImages')
    const { width: screenWidth } = useScreenSize()

    const numOfBlocks = utils.getWidthMeta(screenWidth, addImagesNumOfBlocks)

    return !!images.length && (
        <div className="py-5 border border-kilt-border-gray rounded-xl px-4">
            <DNDKit />
        </div>
    )
}

export default ImageGallery