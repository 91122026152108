import api from "../api"
import { accessGlobalMiscState } from "../stores/misc"
import { accessGlobalUserState } from "../stores/user"
import utils from "../utils"
import commonActions from "./common"

export const campaignActions = {
    createCampaign: async (campaign) => {
        const userState = accessGlobalUserState()
        const miscState = accessGlobalMiscState()
        const optionSelectedListingId = miscState.get('optionSelectedListingId')
        const user = userState.get('user')

        if(user.bonus < campaign.price) {
            utils.showSnackbar('Недостаточно бонусов для активации продвижения', 'error');
            return
        }
        const requestBody = {
            listing_id: optionSelectedListingId,
            campaign_type: campaign.type,
            status: 1,
            paid: campaign.price,
            user_id: user.id
        }

        const response = await commonActions.simpleGetter(api.listingApi.promote, requestBody)
        if(response.campaign?.id) {
            const bonusRes = await commonActions.simpleGetter(api.usersApi.getBonus, user.id)
            if(bonusRes.count) {
                userState.setBonus(bonusRes.count)
            }
            utils.showSnackbar('Успешно', 'success');
            miscState.set('optionPromoteModalOpen', false)
        } else if(response.exists) {
            utils.showSnackbar('Данный тип продвижения уже запущен для данного объявления', 'error');
        }
    }
}