import { accessGlobalUserState } from "./stores/user";
import { accessGlobalEventsState } from "./stores/events";
import api from "./api";
import { accessGlobalMiscState } from "./stores/misc";
import { fieldTransformKeys } from "./constants";

const utils = {
    returnOrError: (resData, arr = false) => {
        //console.log(resData)
        if(resData.error) {
            // Toast.show({
            //     text1: resData.error.msg || 'Произошла ошибка. Пожалуйста, попробуйте позже',
            //     type: 'error',
            //     position: 'top'
            // })
            return arr ? [] : {}
        } else {
            return resData
        }
    },
    objectToParams: (obj) => {
        return Object.entries(obj).map(([k, v]) => `${k}=${v}`).join('&')
    },
    objectFromParams: (params) => {
        try {
            return params.split('&').map(str => ({[str.split('=')[0]]: str.split('=')[1]})).reduce((acc, curr) => ({...acc, ...curr}), {})
        } catch(e) {
            return {}
        }
    },
    fieldTransform: (value, type) => {
        if(fieldTransformKeys.includes(type)) {
            switch(type) {
                case 'numeric':
                    return value.replace(/\D/g,'')
                default:
                    return value
            }
        }
        return value
    },
    getLatLngFromKato: (path) => {
        if(path.startsWith('71')) {
            return {
                lat: 51.1583,
                lng: 71.4314
            };
        } else if(path.startsWith('75')) {
            return {
                lat: 43.23922,
                lng: 76.917284
            };
        } else if(path.startsWith('79')) {
            return {
                lat: 42.3174,
                lng: 69.6207
            };
        }
        return {}
    },
    timeDiff: (date1, date2) => {
        if (!(date1 instanceof Date && date2 instanceof Date))
            throw new RangeError('Invalid date arguments');
            
        const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
        const intervalNames = ['лет', 'месяцев', 'недель', 'дней', 'часов', 'минут', 'секунд'];
        const diff = Math.abs(date2.getTime()-date1.getTime())/1000;
        const index = timeIntervals.findIndex(i=>(diff/i) >= 1);
        const n = Math.floor(diff/timeIntervals[index]);
        const interval = intervalNames[index];
        return [n, interval];
    },
    shallowCopy: (obj) => JSON.parse(JSON.stringify(obj)),
    arrayOfObjectsShallowCopy: (arr) => {
        const array = []
        for(let item of arr) {
            array.push({...item})
        }
        return array
    },
    localize: (value, str) => 
    {       
        return `${value} ${str}`
    },
    getWidthMeta: (screenWidth, meta) => {
        const keys = Object.keys(meta)
        for(const key of keys) {
            if(screenWidth < key) {
                return meta[key]
            }
        }
        return meta[keys[keys.length - 1]]
    },
    event: (name, listing_id = undefined, payload = undefined) => {
        const user = accessGlobalUserState().get()
        const event = {
            name,
            ...(user.id ? { user_id: user.id } : {}),
            ...(user.fcmToken ? { device_id: user.fcmToken } : {}),
            ...(listing_id ? { listing_id } : {}),
            timestamp: new Date().toISOString(),
            ...(payload ? { payload } : {})
        }
        accessGlobalEventsState().add([event])
        console.log(accessGlobalEventsState().getEvents())
    },
    formatPrice: (price) => {
        if(price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");   
        }        
    },
    formatBody: (key, value) => key == 'price' ? parseInt(value.replace(/\s/g, '')) || 0 : value,
    isoDate: (date = undefined) => {
        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
        return (new Date((!date ? Date.now() : new Date(date).getTime()) - tzoffset)).toISOString().slice(0, -1).slice(0,10);
    },
    getImageLink: (listing) => {
        return listing.first_image_cloud ? `${api.cloudImageUrl}/${listing.first_image}/public` : listing.first_image?.startsWith("local-") ? `${api.serverUrl}/uploads/${listing.first_image}` : `${api.cdnImageUrl}/${listing.first_image}`
    },
    getSingleImageLink: (img) => img.cloud ? `${api.cloudImageUrl}/${img.link}/public` : img.link?.startsWith("local-") ? `${api.serverUrl}/uploads/${img.link}` : `${api.cdnImageUrl}/${img.link}`,
    getBase64FromLocalImage: (file, cb) => {
        const reader = new FileReader()
        console.log(file)
        reader.onloadend = () => {
            //console.log('result', reader.result)
            cb({
                file,
                base64: reader.result,
                id: `${new Date().getTime()}-${Math.floor(Math.random() * 1000000000)}`
            })
        }
        reader.readAsDataURL(file)
    },
    singleImageLink: (image) => {
        return !!image.cloud ? `${api.cloudImageUrl}/${image.link}/public` : image.link?.startsWith("local-") ? `${api.serverUrl}/uploads/${image.link}` : `${api.cdnImageUrl}/${image.link}`
    },
    getDates: (s, e, including = false, start = false) => {for(var a=[],dd=new Date(s),ds=dd.setDate(dd.getDate() + 1),d= new Date(start ? ds : s),ee=new Date(e),ef=ee.setDate(ee.getDate() + 1);d<=new Date(!including ? e : ef);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;},
    deepLinkParse: {
        Listing: {
          id: parseInt
        },
        BlogPage: {
          id: parseInt
        }
    },
    stringToInt: (num) => parseInt(num.replace(/ /g, "")),
    formatted: (intVal) => utils.formatPrice(utils.stringToInt(intVal)) || '',
    calculateInitialPercentage: (initialFee, totalCost) => {
        if(!isNaN(utils.stringToInt(initialFee)) && !isNaN(utils.stringToInt(totalCost))) {
            return `${(parseFloat(utils.stringToInt(initialFee) / utils.stringToInt(totalCost)) * 100).toFixed(2)}`
        }
        return ''
    },
    showSnackbar: (text, severity = 'error') => {
        accessGlobalMiscState().set('snackbarContent', text)
        accessGlobalMiscState().set('snackbarSeverity', severity)
        accessGlobalMiscState().set('snackbarOpen', true)
    },
    calculateMonthly: (calculatorForm) => {
        if(Object.keys(calculatorForm).every(key => !isNaN(utils.stringToInt(calculatorForm[key])))) {
            const intCalcForm = {...calculatorForm}
            Object.keys(intCalcForm).forEach(k => {
                intCalcForm[k] = utils.stringToInt(intCalcForm[k])
            })
            const monthly = ((intCalcForm.totalCost - intCalcForm.initialFee) * (1 + (intCalcForm.percentage / 1200) *  intCalcForm.term)) / intCalcForm.term
            return `${utils.formatted('' + parseInt(monthly))}`
        }
        return ''
    }
}

export default utils