import { useEffect, useState } from "react";
import { useKato, useResidential } from "../../hooks/kato";
import map from "../../assets/map.svg";
import pin from "../../assets/pin.svg";
import residential from "../../assets/residential.svg";
import allPin from "../../assets/allpin.svg";
import close from "../../screens/Search/close.svg";
import chevronRight from "../../assets/chevron-right.svg";
import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import Modal from "../Modal/Modal";
import LoadingWrapper from "../Loading/LoadingWrapper";
import useGlobalKatoState from "../../stores/kato";
import { OrbitProgress } from "react-loading-indicators";
import Switch from "../Buttons/Switch";
import GradientButton from "../Buttons/GradientButton";
import useGlobalSearchState from "../../stores/search";
import utils from "../../utils";
import useGlobalAddKatoState from "../../stores/add-kato";
import useGlobalAddState from "../../stores/add";

const SelectRow = ({
  label,
  img,
  rightImg = chevronRight,
  checkbox = false,
  checked,
  disabled = false,
  onClick,
}) => {
  return (
    <div
      className='flex flex-row px-4 py-4 border-b-touchable border-b justify-between cursor-pointer'
      onClick={onClick}
    >
      <div className='flex flex-row'>
        {!!img && <img src={img} />}
        <p className={`${!!img && "ml-4"} font-bold`}>{label}</p>
      </div>
      {checkbox ? (
        <input type='checkbox' checked={checked} disabled={disabled} />
      ) : (
        <img src={rightImg} />
      )}
    </div>
  );
};

const AddressSelector = ({
  wrapperClassName = "",
  type = "search",
  initialViewMode,
  customFieldRender,
  resetKato,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState(initialViewMode || "district");
  const [extendedDistrict, setExtendedDistrict] = useState({ id: -1 });
  const [residentialSearch, setResidentialSearch] = useState("");
  const seachKatoState = useGlobalKatoState();
  const addKatoState = useGlobalAddKatoState();
  const katoState = type === "search" ? seachKatoState : addKatoState;
  const searchState = useGlobalSearchState();
  const addState = useGlobalAddState();
  const finalState = type === "search" ? searchState : addState;
  const kato = katoState.getValue("list");
  const resComplexes = katoState.getValue("resComplexesList");
  const parent = katoState.getValue("currentParent");
  const currentKatoSelected = katoState.getValue("currentKatoSelected");
  const currentResidentialSelected = katoState.getValue(
    "currentResidentialSelected"
  );
  const resComplexesList = resComplexes[parent?.name.replace("г.", "")] || [];
  const list = kato[parent.id] || [];
  const districtList = kato[extendedDistrict.id] || [];
  const chipRender =
    viewMode === "district" ? currentKatoSelected : currentResidentialSelected;
  let titleRender = currentKatoSelected.length
    ? currentKatoSelected
    : currentResidentialSelected.length
    ? currentResidentialSelected
    : [];
  if (type === "add") {
    titleRender =
      initialViewMode === "district"
        ? currentKatoSelected
        : currentResidentialSelected;
  }
  useKato(parent.id, type);
  useKato(extendedDistrict.id, type);
  useResidential(parent?.name.replace("г.", "") || "", type);
  console.log(list);

  const updateParent = (item) => () => {
    katoState.setValue("currentParent", { ...item });
  };

  useEffect(() => {
    // alert(JSON.stringify(titleRender));
  }, [titleRender]);

  const updateKato =
    (
      key,
      item,
      func,
      resetParent = [],
      resetChildren = false,
      state = currentKatoSelected
    ) =>
    () => {
      let value;
      if (func === "setValue" || (func === "mergeValue" && type === "add")) {
        if (func === "mergeValue" && type === "add") {
          func = "setValue";
        }
        value = state.find((i) => i.id === item.id) ? [] : [item];
      } else {
        func = "setValue";
        if (state.find((i) => i.id === item.id)) {
          value = [
            ...state.filter((i) => i.id !== item.id).map((i) => ({ ...i })),
          ];
        } else {
          value = [...state.map((i) => ({ ...i })), item];
        }
        value = value.filter(
          (i) => !resetParent.map((p) => p.id).includes(i.id)
        );
        if (resetChildren) {
          value = value.filter((i) => i.parent_id !== item.id);
        }
      }
      katoState[func](key, value);
    };

  const reset = () => {
    katoState.reset();
  };

  const apply = () => {
    let obj = {};
    if (viewMode === "district") {
      const finalList = [];
      for (let item of currentKatoSelected) {
        if (item.parent_id === "0") {
          finalList.push(parent.id);
        } else {
          if (item.id in kato) {
            finalList.push([parent.id, item.id].join(","));
          } else if (item.parent_id in kato) {
            finalList.push([parent.id, item.parent_id, item.id].join(","));
          }
        }
      }
      console.log(utils.shallowCopy(finalList));
      obj = {
        kato_path: utils.shallowCopy(finalList),
      };
      if (type === "search") {
        obj.residential_complex = "";
        katoState.setValue("currentResidentialSelected", []);
      }
    } else {
      obj = {
        residential_complex:
          type === "search"
            ? currentResidentialSelected.map((r) => r.id)
            : currentResidentialSelected[0]?.id,
      };
      if (type === "search") {
        obj.kato_path = "";
        katoState.setValue("currentKatoSelected", []);
      }
      console.log(currentResidentialSelected);
      if (type === "add") {
        obj.residential_complex_name =
          currentResidentialSelected[0]?.residential_complex_name || "";
        obj.address_string =
          currentResidentialSelected[0]?.address_string || "";
        if (
          !!currentResidentialSelected[0]?.lat &&
          !!currentResidentialSelected[0]?.lng
        ) {
          obj.lat = currentResidentialSelected[0]?.lat;
          obj.lng = currentResidentialSelected[0]?.lng;
        }
      }
    }
    finalState.mergeForm(obj);
    setModalOpen(false);
  };

  return (
    <>
      <div className={wrapperClassName} onClick={() => setModalOpen(true)}>
        {!!customFieldRender ? (
          customFieldRender(titleRender)
        ) : (
          <>
            <img alt='Карта' src={map} />
            {titleRender.length ? (
              <div className='ml-3 py-2 px-3 rounded-md border border-[#D9D9D9] flex-1'>
                <p className='line-clamp-1'>
                  {titleRender
                    .map(
                      (item) => item.name || item.residential_complex_name || ""
                    )
                    .join(" / ")}
                </p>
              </div>
            ) : (
              <p className='ml-2.5 font-semibold text-kilt-muted'>
                Город, район, название ЖК
              </p>
            )}
          </>
        )}
      </div>
      <Modal
        dimensions='w-full h-full xl:w-2/5 xl:h-4/5 overflow-y-scroll'
        visible={modalOpen}
        setVisible={setModalOpen}
      >
        <div className='flex flex-col h-full'>
          <div className='flex flex-row justify-between items-center p-4'>
            <img
              src={close}
              onClick={() => setModalOpen(false)}
              className='cursor-pointer'
            />
            <p className='text-info-gray cursor-pointer' onClick={reset}>
              Сбросить
            </p>
          </div>
          <p className='font-bold mx-4 mt-1'>
            {parent.id === 0 ? "Город" : parent.name}
          </p>
          <div className='flex flex-row mx-4 flex-wrap mt-3'>
            {chipRender.map((item) => (
              <div className='mr-2 mb-2 py-1 px-3 rounded-lg bg-touchable text-sm'>
                {item.name || item.residential_complex_name || ""}
              </div>
            ))}
          </div>
          {parent.id !== 0 && type === "search" && (
            <Switch
              value={viewMode}
              val1='district'
              val2='residential'
              label1='Район'
              label2='ЖК'
              setValue={setViewMode}
            />
          )}
          <LoadingWrapper
            loading={!list.length}
            render={
              <div className='w-full flex flex-row justify-center'>
                <OrbitProgress size='small' color='#3F4FE0' />
              </div>
            }
          >
            {parent.id === 0 &&
              list.map((item) => (
                <SelectRow
                  img={pin}
                  label={item.name}
                  onClick={updateParent(item)}
                />
              ))}
            {parent.id !== 0 && viewMode === "district" && (
              <div className='flex flex-col flex-1 overflow-y-scroll'>
                {type === "search" && (
                  <SelectRow
                    label={
                      parent.name?.includes("область")
                        ? "Выбрать всю область"
                        : "Выбрать весь город"
                    }
                    img={allPin}
                    checkbox
                    checked={
                      !!currentKatoSelected.find((i) => i.id === parent.id)
                    }
                    onClick={updateKato(
                      "currentKatoSelected",
                      { ...parent },
                      "setValue"
                    )}
                  />
                )}
                {[...list]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((item) => (
                    <>
                      <SelectRow
                        label={item.name}
                        rightImg={
                          extendedDistrict.id === item.id
                            ? chevronUp
                            : chevronDown
                        }
                        onClick={() =>
                          setExtendedDistrict(
                            extendedDistrict.id === item.id ? { id: -1 } : item
                          )
                        }
                      />
                      {extendedDistrict.id === item.id && (
                        <LoadingWrapper
                          loading={!(extendedDistrict?.id in kato)}
                          render={
                            <div className='w-full flex flex-row justify-center'>
                              <OrbitProgress size='small' color='#3F4FE0' />
                            </div>
                          }
                        >
                          <SelectRow
                            label='Выбрать весь район'
                            img={allPin}
                            checkbox
                            checked={
                              !!currentKatoSelected.find(
                                (i) => i.id === extendedDistrict.id
                              )
                            }
                            onClick={updateKato(
                              "currentKatoSelected",
                              extendedDistrict,
                              "mergeValue",
                              [parent],
                              true
                            )}
                          />
                          {[...districtList]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((districtItem) => (
                              <>
                                <SelectRow
                                  label={districtItem.name}
                                  img={pin}
                                  checkbox
                                  checked={
                                    !!currentKatoSelected.find(
                                      (i) => i.id === districtItem.id
                                    )
                                  }
                                  onClick={updateKato(
                                    "currentKatoSelected",
                                    { ...districtItem },
                                    "mergeValue",
                                    [parent, extendedDistrict]
                                  )}
                                />
                              </>
                            ))}
                        </LoadingWrapper>
                      )}
                    </>
                  ))}
              </div>
            )}
            {parent.id !== 0 && viewMode === "residential" && (
              <div className='flex flex-col px-4 flex-1 overflow-y-scroll'>
                <input
                  value={residentialSearch}
                  onChange={(e) => setResidentialSearch(e.target.value)}
                  placeholder='Поиск по названию'
                  className='border-[1.5px] border-touchable bg-white rounded-xl px-4 py-2'
                />
                <div className='flex-1 pb-4 overflow-y-scroll'>
                  <div className='flex flex-col'>
                    <LoadingWrapper
                      loading={!resComplexesList.length}
                      render={
                        <div className='w-full flex flex-row justify-center'>
                          <OrbitProgress size='small' color='#3F4FE0' />
                        </div>
                      }
                    >
                      {resComplexesList
                        .filter((r) =>
                          r.residential_complex_name
                            ?.toLowerCase()
                            .includes(residentialSearch.toLocaleLowerCase())
                        )
                        .map((residentialItem) => (
                          <SelectRow
                            label={residentialItem.residential_complex_name}
                            img={residential}
                            checkbox
                            checked={
                              !!currentResidentialSelected.find(
                                (i) => i.id === residentialItem.id
                              )
                            }
                            onClick={updateKato(
                              "currentResidentialSelected",
                              { ...residentialItem },
                              "mergeValue",
                              [],
                              false,
                              currentResidentialSelected
                            )}
                          />
                        ))}
                    </LoadingWrapper>
                  </div>
                </div>
              </div>
            )}
          </LoadingWrapper>
          {parent.id !== 0 && (
            <GradientButton
              text='Применить'
              onClick={apply}
              additionalClass='mx-4 my-3'
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddressSelector;
