import {hookstate, useHookstate} from '@hookstate/core';
import { localstored } from '@hookstate/localstored';
import { initialKatoParent } from '../constants';

const initial = {
  list: {},
  resComplexesList: {},
  currentParent: initialKatoParent,
  currentKatoSelected: [],
  currentResidentialSelected: []
}

const store = hookstate({...initial})

const wrapState = (state) => ({
    mergeList: (value) => state.list.merge(value),
    getValue: (key) => state[key].value,
    setValue: (key, value) => {
        state[key].set(value)
    },
    mergeValue: (key, value) => {
      state[key].merge(value)
    },
    reset: () => {
      for(let [key, value] of Object.entries(initial).filter(([k, v]) => !['list', 'resComplexesList'].includes(k))) {
        state[key].set(value)
      }
    }
})
 
const useGlobalKatoState = () => wrapState(useHookstate(store))

export default useGlobalKatoState
export const accessGlobalKatoState = () => wrapState(store)
