import { useGlobalMiscState } from "../../stores/misc"
import gallery from '../../assets/gallery.svg'
import utils from "../../utils"

const AddImage = ({}) => {
    const miscState = useGlobalMiscState()

    const onFileInputChange = (e) => {
        const images = []
        for(let file of e.target.files) {
            console.log(e.target.files)
            const fileNames = [...e.target.files].map(f => f.name)
            console.log(fileNames)
            utils.getBase64FromLocalImage(file, (f) => {
                images.push(f)
                if(images.length === e.target.files?.length) {
                    const finalImages = images.sort((a, b) => fileNames.indexOf(a.file.name) - fileNames.indexOf(b.file.name))
                    miscState.add('addListingImages', finalImages)
                }
            })
        }
    }

    return (
        <label>
            <input multiple hidden type="file" accept="image/jpeg,image/png" onChange={onFileInputChange} />
            <div className="w-full border border-kilt-border-gray rounded-xl py-6 items-center flex flex-col cursor-pointer">
                <img src={gallery} />
                <p className="text-typography font-bold mt-4">
                    Добавить фото
                </p>
            </div> 
        </label>      
    )
}

export default AddImage