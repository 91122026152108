import LoadingWrapper from "../Loading/LoadingWrapper"

const GradientButton = ({onClick, text, hasLoading = false, loadingStyle = {}, loading, customRender, additionalClass = '', ...rest}) => {
    return (
        <button onClick={onClick} className={`gradient-button text-white font-bold py-2.5 px-6 rounded-xl flex flex-row items-center justify-center ${additionalClass}`}>
            {hasLoading ? (
                <LoadingWrapper loading={loading} color="#FFFFFF" viewStyle={loadingStyle} size="small">
                    {text}
                </LoadingWrapper>
            ) : text}
        </button>
    )
}

export default GradientButton