import { useEffect, useState } from "react";
import { listingModerateSellerRows } from "../../constants";
import { useListing } from "../../hooks/listing";
import utils from "../../utils";
import api from "../../api";
import axios from "axios";

export const ModerationActions = ({ id, onClose, timer, userId }) => {
  const [rejectComment, setRejectComment] = useState("");
  const [penaltyComment, setPenaltyComment] = useState("");
  const [penalties, setPenalties] = useState([]);
  const listing = useListing(id);

  const onRejectCommentChange = (e) => {
    setRejectComment(e.target.value);
  };

  const onPenaltyCommentChange = (e) => {
    setPenaltyComment(e.target.value);
  };

  const penaltyHandler = async () => {
    const data = {
      reason: penaltyComment,
      userId: listing.seller_id,
      listingId: listing.id,
      moderator_id: userId,
    };

    const response = await axios.post(api.serverUrl + "/admin/penalty", data);
    if (response.data.success) {
      utils.showSnackbar("Оштрафовано");
      setPenaltyComment("");
      onClose();
    } else {
      alert("Ошибка");
      console.log("error: ", response);
    }
  };

  const loadPenalties = async () => {
    const res = await axios.get(
      api.serverUrl + "/admin/penalty/" + listing.seller_id
    );
    if (res) {
      setPenalties(res.data?.penalties);
      if (res.data?.penalties.length >= 3) {
        setPenaltyComment("block");
      }
    }
  };

  const rejectListing = async () => {
    if (!rejectComment.length) {
      utils.showSnackbar("Пожалуйста, заполните комментарий");
      return;
    }
    const timerTime = new Date() - timer;

    const responseRaw = await fetch(
      `${api.serverUrl}/admin/listings/reject/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          message: rejectComment,
          timer: Math.floor((new Date().getTime() - timer) / 1000),
          moderatorId: userId,
        }),
      }
    );
    const response = await responseRaw.json();
    if (response.success) {
      utils.showSnackbar("Успешно");
      onClose();
    }
  };

  const approveListing = async () => {
    const responseRaw = await fetch(
      `${api.serverUrl}/admin/listings/approve/${id}?timer=${Math.floor(
        (new Date().getTime() - timer) / 1000
      )}&moderatorId=${userId}`
    );
    console.log(userId);
    const response = await responseRaw.json();
    if (response.success) {
      utils.showSnackbar("Успешно");
      onClose();
    }
  };

  useEffect(() => {
    if (listing.seller_id) {
      loadPenalties();
    }
  }, [listing.seller_id]);

  const renderSellerRow = (row) => {
    return (
      <div className='flex flex-row'>
        <span className='flex-[3] font-bold'>{row.label}</span>
        {"customRender" in row ? (
          row.customRender(listing)
        ) : (
          <span className='flex-[7]'>{listing[row.renderKey]}</span>
        )}
      </div>
    );
  };
  if ("id" in listing) {
    return (
      <div
        className='lg:container flex flex-col mb-7'
        style={{
          borderTop: "1px solid #E5E7EB",
          paddingTop: "20px",
        }}
      >
        <span className='font-bold text-2xl mb-3'>Информция о праодавце</span>
        {listingModerateSellerRows.map(renderSellerRow)}

        <div
          className='lg:container flex flex-col mb-7'
          style={{
            borderTop: "1px solid #E5E7EB",
            marginTop: "20px",
          }}
        >
          <div className='lg:container flex flex-col mb-7'>
            <span className='font-bold text-2xl mb-3 pt-3'>
              История штрафов
            </span>
            {penalties ? (
              penalties.map((penalty) => (
                <div
                  key={penalty.id}
                  className='flex flex-col border border-kilt-touchable rounded-lg p-3 mb-3'
                >
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Дата</span>
                    <span className='flex-[7]'>
                      {new Date(penalty.createdAt).toLocaleDateString()}{" "}
                      {new Date(penalty.createdAt).toLocaleTimeString()}
                    </span>
                  </div>
                  <div className='flex flex-row'>
                    <span className='flex-[3] font-bold'>Причина</span>
                    <span className='flex-[7]'>{penalty.reason}</span>
                  </div>
                </div>
              ))
            ) : (
              <div>История пуста.</div>
            )}
          </div>
        </div>

        <div className='flex flex-col flex-1 pr-4 mt-3'>
          {penalties?.length < 3 && (
            <textarea
              className='mb-4 border border-kilt-touchable rounded-lg p-3'
              placeholder='Причина'
              rows={3}
              value={penaltyComment}
              onChange={onPenaltyCommentChange}
            />
          )}
          <button
            onClick={penaltyHandler}
            style={
              penaltyComment.length > 0 || penalties?.length >= 3
                ? { backgroundColor: "#7c0000" }
                : { backgroundColor: "#c49393", cursor: "not-allowed" }
            }
            disabled={!penaltyComment.length > 0 || !penalties?.length >= 3}
            className='text-white font-bold py-2.5 px-6 rounded-xl flex flex-row items-center justify-center'
          >
            {penalties?.length >= 3 ? "Заблокировать" : "Оштрафовать"}
          </button>
        </div>
        <div className='flex flex-row mt-8'>
          <div className='flex flex-col flex-1 pr-4'>
            <textarea
              className='mb-4 border border-kilt-touchable rounded-lg p-3'
              placeholder='Комментарий'
              rows={3}
              value={rejectComment}
              onChange={onRejectCommentChange}
            />
            <button
              onClick={rejectListing}
              className='bg-kilt-error text-white font-bold py-2.5 px-6 rounded-xl flex flex-row items-center justify-center'
            >
              Отклонить
            </button>
          </div>
          <div className='pl-4 flex flex-col flex-1'>
            <button
              onClick={approveListing}
              className='bg-kilt-green text-white font-bold py-2.5 px-6 rounded-xl flex flex-row items-center justify-center'
            >
              Одобрить
            </button>
          </div>
        </div>
      </div>
    );
  }
};
