import { useNavigate, useParams } from "react-router-dom"
import { partnerTabs, personalMeta, profileTabs } from "../../constants"
import useGlobalUserState from "../../stores/user"
import chevronRight from "../../assets/chevron-right.svg"
import { useEffect } from "react"
import commonActions from "../../actions/common"
import api from "../../api"
import WithdrawBonuses from "./WithdrawBonuses"
import WithdrawHistory from "./WithdrawHistory"

const tabRender = {
    'withdraw': WithdrawBonuses,
    'history': WithdrawHistory
}


const Partner = ({}) => {
    const { tab: paramTab } = useParams()
    const navigate = useNavigate()
    const userState = useGlobalUserState()
    const user = userState.get('user')
    const navigateTo = (tab) => () => {
        navigate(`/partner/${tab}`)
    }

    const checkUserRole = async () => {
        const response = await commonActions.simpleGetter(api.usersApi.checkRole, {
            id: user.id,
            body: {
                role_id: 2
            }
        })
        if(!response.access) {
            navigate('/')
        }
    }

    useEffect(() => {
        checkUserRole()
    }, [])

    const renderTab = (tab) => {
        switch(tab.tabId) {
            default:
                return (
                    <div className={`flex flex-row ${paramTab === tab.tabId ? 'bg-touchable' : 'bg-transparent'} px-4 py-5 rounded-xl justify-between cursor-pointer`} onClick={navigateTo(tab.tabId)}>
                        <p className="font-semibold">{tab.label}</p>
                        <img src={chevronRight} />
                    </div>
                )
        }
    }

    const renderTabInfo = (tabId) => {
        const Component = tabRender[tabId]
        if(!!Component) {
            return (<Component />)
        }
    }

    return (
        <div className="xl:container grid grid-cols-12">
            <div className="col-span-3 flex flex-col">
                {partnerTabs.map(t => renderTab(t))}
            </div>
            <div className="col-span-9 flex flex-col px-[10px]">
                {renderTabInfo(paramTab)}
            </div>
        </div>
    )
}

export default Partner