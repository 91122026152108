import {hookstate, useHookstate} from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

const store = hookstate({
    config: {}
}, localstored({
    key: 'config-store',
    engine: localStorage
}))

const wrapState = (state) => ({
    get: () => state.config.value,
    setConfig: (config) => {
        state.set({config})
    }
})

const useGlobalConfigState = () => wrapState(useHookstate(store))

export default useGlobalConfigState
export const accessGlobalConfigState = () => wrapState(store)
