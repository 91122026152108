import bonusLogo from '../../assets/bonus-logo.svg'
import useGlobalUserState from '../../stores/user'
import utils from '../../utils'

export const BonusFullWidth = ({additionalClassName = ""}) => {
    const userState = useGlobalUserState()
    const user = userState.get('user')
    return (
        <div className={`flex flex-row rounded-xl bg-touchable px-4 justify-between ${additionalClassName}`}>
            <span className="font-medium my-3 text-lg">
                Баланс
            </span>
            <div className="flex flex-row items-center">
                <div className="flex flex-row items-center">
                    <img src={bonusLogo} />
                    <span className="text-lg font-bold ml-1">
                        {utils.formatPrice(`${user.bonus}`)}
                    </span>
                </div>
            </div>
        </div>
    )
}