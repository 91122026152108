import api from "../../api"
import LoadingWrapper from "../../components/Loading/LoadingWrapper"
import { useBlogItems } from "../../hooks/blog"

const HomeBlog = () => {
    const blogItems = useBlogItems()

    return (
        <LoadingWrapper loading={!blogItems.length}>
            <div className="col-span-12 grid grid-cols-12 pl-4 xl:pl-0">
                <div className="col-span-12 mb-5 xl:mb-7">
                    <p className="font-semibold text-[32px] text-kilt-black ">
                        Наш блог
                    </p>
                </div>
                <div className="hidden xl:flex col-span-4 relative bg-center bg-cover rounded-xl flex-col items-center justify-end px-5 py-10" style={{ backgroundImage: `url(${api.serverUrl}/uploads/${blogItems[0].image})` }}>
                    <div className="w-full h-full black-overlay absolute top-0 left-0 rounded-xl" />
                    <p className="text-2xl font-bold text-white z-20">
                        {blogItems[0].title}
                    </p>
                </div>
                <div className="hidden xl:flex col-span-8 flex-col max-h-[30vh]">
                    {blogItems.slice(1, 4).map((blogItem, i) => (
                        <div className={`bg-touchable px-5 py-2 flex flex-row mx-3 ${i !== 0 && 'mt-3'} rounded-xl`}>
                            <div className="w-9/12 pt-3 pb-5">
                                <p className="text-sm text-typography opacity-50">
                                    {blogItem.createdAt}
                                </p>
                                <p className="text-xl font-bold text-typography min-h-14">
                                    {blogItem.title}
                                </p>
                            </div>
                            <div className="w-3/12 bg-cover bg-center rounded-xl" style={{backgroundImage: `url(${api.serverUrl}/uploads/${blogItem.image})`}}>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex xl:hidden col-span-12 flex-row overflow-x-scroll">
                    {blogItems.map(blogItem => (
                        <div className="min-w-[70vw] flex flex-col rounded-xl bg-kilt-gray p-[6px] mr-3">
                            <div className="w-full min-h-[20vh] bg-cover bg-center rounded-xl" style={{backgroundImage: `url(${api.serverUrl}/uploads/${blogItem.image})`}} />
                            <p className="font-bold text-kilt-black my-3 mx-[6px] line-clamp-2">
                                {blogItem.title}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </LoadingWrapper>
    )
}

export default HomeBlog